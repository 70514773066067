import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  Card,
  Typography,
  List,
  Image,
  Row,
  Col,
  Space,
  Tooltip,
  Badge,
  Rate,
  Timeline,
  Tag,
  Collapse,
  Divider,
} from "antd";
import {
  BiUserPlus,
  BiConversation,
  BiBroadcast,
  BiPrinter,
} from "react-icons/bi";
import { AiOutlineContacts } from "react-icons/ai";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { MdHistoryToggleOff, MdHistory } from "react-icons/md";
import { ImStopwatch } from "react-icons/im";
import { CloseOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../contexts/globalContext";
import { fileIcon, channels } from "../../constants/enums";
import { getUserDateTime } from "../../utils/utility";
import ConversationInfoPanel from "../conversations/ConversationInfoPanel";
import ContactManagerFormPanel from "../contact-manager/ContactManagerFormPanel";
import parsePhoneNumber from "libphonenumber-js";
import { Translation } from "../../translation/translation";
import { TabLoader } from "../../shared-components/LoadingIndicator";
import PhoneNumber from "libphonenumber-js";
import PrintInvoicePanel from "./PrintInvoicePanel";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";
import DeliveryServicesPanel from "./DeliveryServicesPanel";
import moment from "moment";

const { Text } = Typography;
const { Item } = Timeline;
const { Panel } = Collapse;

const ClientResponsesInfoPanelV2 = ({
  selectedResponse,
  isContactAlreadyExist,
}) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const navigate = useNavigate();
  const translation = Translation().clientResponses.infoPanel;

  const [showPrintInvoicePanel, setShowPrintInvoicePanel] = useState(false);
  const printFrameRef = useRef(null);

  const currentResponseCategory = state.clientResponses.currentResponseCategory;
  // For handling view conversation, create new broadcast
  let currentResponse = state.clientResponses.currentResponse;
  const responsesTimeline = state.clientResponses.responsesTimeline;
  const permission = state.currentUser.permission;

  // Add "+" to conversationId if it is whatsapp channel
  if (currentResponse?.channel.toUpperCase() === "WHATSAPP") {
    currentResponse.conversationId = currentResponse.conversationId.includes(
      "+"
    )
      ? currentResponse.conversationId
      : "+" + currentResponse.conversationId;
  }

  const formatPhoneNumber = (phoneNumber) => {
    const parsedNumber = PhoneNumber(phoneNumber);
    return parsedNumber?.format("INTERNATIONAL"); // Use 'INTERNATIONAL' for international format
  };

  // For handling print invoice
  const handlePrintInvoice = () => {
    const contentToPrint = document.getElementById("contentToPrint");
    const printFrame = printFrameRef.current;

    if (contentToPrint && printFrame) {
      // Set the content of the iframe
      printFrame.contentDocument.body.innerHTML = contentToPrint.innerHTML;

      // Trigger the print function for the iframe
      printFrame.contentWindow.print();
      setShowPrintInvoicePanel(false);
    } else {
      alert("Content to print not found");
    }
  };

  const renderInfoPanel = (selectedResponse) => {
    if (!state.infoPanel["conversation-info-panel"]?.visible) {
      switch (currentResponseCategory) {
        // For Terms And Conditions Agreed needs to show basic info only. so reusing Order category template
        case "Terms And Conditions Agreed":
        // Order, Order Confirmation categories are same
        case "Order":
        case "Order Confirmation":
        case "Requested For Products":
        case "Register Package":
          return (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Customer Details</Text>
                <Text ellipsis>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={15}>
                    <Col span={24}>
                      <Text ellipsis>{selectedResponse.userProfileName}</Text>
                    </Col>

                    {selectedResponse.deliveryAddress && (
                      <Col span={24}>
                        <Text>{selectedResponse.deliveryAddress}</Text>
                      </Col>
                    )}

                    {selectedResponse.userPhoneNumber && (
                      <Col span={24}>
                        <Text ellipsis>
                          {formatPhoneNumber(
                            selectedResponse.userPhoneNumber.startsWith("+")
                              ? selectedResponse.userPhoneNumber
                              : `+${selectedResponse.userPhoneNumber}`
                          )}
                        </Text>
                      </Col>
                    )}

                    {selectedResponse.userEmail && (
                      <Col span={24}>
                        <Text ellipsis>{selectedResponse.userEmail}</Text>
                      </Col>
                    )}
                  </Col>

                  {selectedResponse.deliveryDate && (
                    <Col span={9}>
                      <Col
                        span={24}
                        style={{
                          position: "absolute",
                          bottom: 0,
                        }}
                      >
                        <Text>Date : </Text>
                        <Tooltip
                          title={getUserDateTime(
                            selectedResponse.deliveryDate,
                            state.currentUser.user.timeZone,
                            state.currentUser.user.dateFormat
                          )}
                        >
                          <Text ellipsis>
                            {getUserDateTime(
                              selectedResponse.deliveryDate,
                              state.currentUser.user.timeZone,
                              state.currentUser.user.dateFormat
                            )}
                          </Text>
                        </Tooltip>
                      </Col>
                    </Col>
                  )}
                </Row>
              </Card>

              {selectedResponse.bookingPersonName && (
                <Space
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Text strong>Booking Person Details</Text>
                </Space>
              )}

              {selectedResponse.bookingPersonName && (
                <Card
                  style={{
                    marginTop: 9,
                  }}
                  size="small"
                >
                  <Row gutter={[0, 10]}>
                    <Col span={3}>
                      <Text>Name</Text>
                    </Col>
                    <Col span={2}>:</Col>
                    <Col span={19}>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsis>
                          {selectedResponse.bookingPersonName || "-"}
                        </Text>

                        <Text>
                          {getUserDateTime(
                            selectedResponse.bookingDate,
                            state.currentUser.user.timeZone,
                            state.currentUser.user.dateFormat
                          ) || "-"}
                        </Text>
                      </Space>
                    </Col>

                    <Col span={3}>
                      <Text>Age</Text>
                    </Col>
                    <Col span={2}>:</Col>
                    <Col span={17}>
                      <Text>{selectedResponse.bookingPersonAge || "-"}</Text>
                    </Col>

                    <Col span={3}>
                      <Text>Count</Text>
                    </Col>
                    <Col span={2}>:</Col>
                    <Col span={17}>
                      <Text>{selectedResponse.bookingPersonCount || "-"}</Text>
                    </Col>

                    <Col span={3}>
                      <Text>Email</Text>
                    </Col>
                    <Col span={2}>:</Col>
                    <Col span={17}>
                      <Text>{selectedResponse.bookingUserEmail || "-"}</Text>
                    </Col>
                  </Row>
                </Card>
              )}

              {selectedResponse.packages && (
                <Space
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Text strong>Packages Details</Text>
                </Space>
              )}

              {selectedResponse.packages && (
                <List
                  dataSource={selectedResponse.packages}
                  renderItem={(selectedPackage, index) => (
                    <Card
                      key={index}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      size="small"
                    >
                      <Card.Meta
                        title={selectedPackage.name}
                        description={
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Col span={12}>
                              <Text>Quantity: {selectedPackage.quantity}</Text>
                            </Col>

                            <Text strong>
                              {selectedPackage?.price?.currency}{" "}
                              {selectedPackage?.price?.singlePrice}
                            </Text>
                          </Row>
                        }
                      />
                    </Card>
                  )}
                />
              )}

              {selectedResponse.items && (
                <Space
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Text strong>Order Items</Text>
                </Space>
              )}

              {selectedResponse.items && (
                <List
                  dataSource={selectedResponse.items}
                  renderItem={(item, index) => (
                    <Card
                      key={index}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      size="small"
                    >
                      <Card.Meta
                        avatar={
                          <Image
                            src={
                              item.productImageURL ||
                              "/images/image-placeholder.png"
                            }
                            preview={false}
                            width={60}
                            height={60}
                            style={{
                              objectFit: "cover",
                              objectPosition: "center",
                              border: "1px solid #f0f0f0",
                              borderRadius: 5,
                            }}
                          />
                        }
                        title={item.name}
                        description={
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Col span={12}>
                              <Text>Quantity: {item.quantity}</Text>
                            </Col>

                            <Text strong>
                              {item.price.currency} {item.price.totalPrice}
                            </Text>
                          </Row>
                        }
                      />
                    </Card>
                  )}
                />
              )}

              {selectedResponse.price && (
                <Row style={{ marginBottom: 12 }}>
                  <Col
                    span={18}
                    style={{
                      textAlign: "Right",
                    }}
                  >
                    <Text strong>Total Price </Text>
                  </Col>
                  <Col span={1}>
                    <Text strong> :</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Text strong>
                      {selectedResponse.price.currency}{" "}
                      {selectedResponse.price.totalPrice}
                    </Text>
                  </Col>
                </Row>
              )}

              {selectedResponse.paymentMethod && (
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Text strong>Payment Details</Text>
                  </Col>
                  <Col span={5}>
                    <Text>Method</Text>
                  </Col>
                  <Col span={2}>:</Col>
                  <Col span={14}>
                    <Text>{selectedResponse.paymentMethod}</Text>
                  </Col>

                  <Col span={5}>
                    <Text>Status</Text>
                  </Col>
                  <Col span={2}>:</Col>
                  <Col span={12}>
                    <Text>{selectedResponse.paymentStatus || "-"}</Text>
                  </Col>
                </Row>
              )}

              {selectedResponse.orderType && (
                <Row
                  gutter={[10, 10]}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Col span={24}>
                    <Text strong>Delivery Details</Text>
                  </Col>
                  <Col span={5}>
                    <Text>Type</Text>
                  </Col>
                  <Col span={2}>:</Col>
                  <Col span={14}>
                    <Text>
                      {selectedResponse.orderType ||
                        selectedResponse.deliveryType}
                    </Text>
                  </Col>

                  {selectedResponse.timeSlot && (
                    <>
                      <Col span={5}>
                        <Text>Time Slot</Text>
                      </Col>
                      <Col span={2}>:</Col>
                      <Col span={12}>
                        <Text>{selectedResponse.timeSlot}</Text>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </>
          );

        case "Appointment Booking":
          switch (selectedResponse.subCategory) {
            case "Clinic Appointment":
              return (
                <div>
                  <Row>
                    <Text strong>Patient Details</Text>
                  </Row>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row>
                      <Col span={15}>
                        <Row gutter={[0, 4]}>
                          <Col span={24}>
                            <Text
                              ellipsis
                              style={{
                                color: "rgb(0 0 0)",
                                fontSize: 15,
                              }}
                            >
                              {selectedResponse.userProfileName}
                            </Text>
                          </Col>

                          {selectedResponse.userPhoneNumber && (
                            <Col span={24}>
                              <Text>
                                {formatPhoneNumber(
                                  selectedResponse.userPhoneNumber.startsWith(
                                    "+"
                                  )
                                    ? selectedResponse.userPhoneNumber
                                    : `+${selectedResponse.userPhoneNumber}`
                                )}
                              </Text>
                            </Col>
                          )}

                          <Col span={24}>
                            <Text> {selectedResponse.userAddress}</Text>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={9}>
                        <Col
                          span={24}
                          style={{
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <Text>DOB : </Text>
                          <Text ellipsis>
                            {selectedResponse.dateOfBirth ?? "-"}
                          </Text>
                          <Text ellipsis>
                            {selectedResponse.age
                              ? selectedResponse.age + ","
                              : ""}{" "}
                            {selectedResponse.gender}
                          </Text>
                        </Col>
                      </Col>
                    </Row>
                  </Card>

                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Doctor Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row>
                      <Col span={15}>
                        <Col
                          span={24}
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Text
                            ellipsis
                            style={{
                              color: "rgb(0 0 0)",
                              fontSize: 15,
                            }}
                          >
                            {selectedResponse.doctor.name}
                          </Text>
                        </Col>

                        <Col span={24}>
                          <Text> {selectedResponse.doctor.clinic}</Text>
                        </Col>
                      </Col>

                      <Col span={9}>
                        <Col
                          span={24}
                          style={{
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <Text>Specialization : </Text>
                          <Text> {selectedResponse.doctor.specialization}</Text>
                        </Col>
                      </Col>
                    </Row>
                  </Card>

                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Appointment Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row>
                      <Col span={15}>
                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={5}>
                            <FaRegCalendarAlt size={17} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.appointment.date}</Text>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={5}>
                            <FaRegClock size={18} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.appointment.time}</Text>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={5}>
                            <Text strong>Reason</Text>
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.appointment.reason}</Text>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8}>
                        {selectedResponse.appointment.duration && (
                          <Row
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <Col span={5}>
                              <ImStopwatch size={19} />
                            </Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>
                              <Text>
                                {" "}
                                {selectedResponse.appointment.duration}
                              </Text>
                            </Col>
                          </Row>
                        )}

                        {selectedResponse.appointment.status && (
                          <>
                            <Col span={24}>
                              <Text strong>Status </Text>:
                            </Col>

                            <Col span={24}>
                              <Badge
                                status={
                                  selectedResponse.appointment.status ===
                                  "Confirmed"
                                    ? "success"
                                    : "default"
                                }
                                text={selectedResponse.appointment.status}
                              />
                            </Col>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </div>
              );

            default:
              return (
                <div>
                  <Row>
                    <Text strong>Customer Details</Text>
                  </Row>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}

                      <Col span={24}>
                        <Text> {selectedResponse.userAddress}</Text>
                      </Col>
                    </Row>
                  </Card>

                  {selectedResponse.appointment && (
                    <>
                      <Space
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <Text strong>Appointment Details</Text>
                      </Space>

                      <Card
                        style={{
                          marginTop: 9,
                        }}
                        size="small"
                      >
                        <Row>
                          <Col span={15}>
                            <Row
                              style={{
                                marginBottom: 5,
                              }}
                            >
                              <Col span={5}>
                                <FaRegCalendarAlt size={17} />
                              </Col>
                              <Col span={2}>:</Col>
                              <Col span={14}>
                                <Text>
                                  {" "}
                                  {selectedResponse.appointment.date}
                                </Text>
                              </Col>
                            </Row>

                            {selectedResponse.appointment.time && (
                              <Row
                                style={{
                                  marginBottom: 5,
                                }}
                              >
                                <Col span={5}>
                                  <FaRegClock size={18} />
                                </Col>
                                <Col span={2}>:</Col>
                                <Col span={14}>
                                  <Text>
                                    {" "}
                                    {selectedResponse.appointment.time}
                                  </Text>
                                </Col>
                              </Row>
                            )}

                            <Row>
                              <Col span={5}>
                                <Text strong>Service</Text>
                              </Col>
                              <Col span={2}>:</Col>
                              <Col span={14}>
                                <Text>
                                  {" "}
                                  {selectedResponse.appointment.reason}
                                </Text>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={8}>
                            {selectedResponse.appointment.duration && (
                              <Row
                                style={{
                                  marginBottom: 5,
                                }}
                              >
                                <Col span={5}>
                                  <ImStopwatch size={19} />
                                </Col>
                                <Col span={2}>:</Col>
                                <Col span={14}>
                                  <Text>
                                    {selectedResponse.appointment.duration}
                                  </Text>
                                </Col>
                              </Row>
                            )}

                            {selectedResponse.appointment.status && (
                              <>
                                <Col span={24}>
                                  <Text strong>Status </Text>:
                                </Col>

                                <Col span={24}>
                                  <Badge
                                    status={
                                      selectedResponse.appointment.status ===
                                      "Confirmed"
                                        ? "success"
                                        : "default"
                                    }
                                    text={selectedResponse.appointment.status}
                                  />
                                </Col>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </>
                  )}
                </div>
              );
          }

        case "Requested Demo":
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Customer Details</Text>
                <Text>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={15}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}

                      {selectedResponse.userEmail && (
                        <Col span={24}>
                          <Text> {selectedResponse.userEmail}</Text>
                        </Col>
                      )}

                      <Col span={24}>
                        <Text>{selectedResponse.userAddress}</Text>
                      </Col>
                    </Row>
                  </Col>

                  {selectedResponse.company && (
                    <Col span={9}>
                      <Col
                        span={24}
                        style={{
                          position: "absolute",
                          bottom: 0,
                        }}
                      >
                        <Text>Company : </Text>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.company}
                        </Text>
                      </Col>
                    </Col>
                  )}
                </Row>
              </Card>

              {selectedResponse.demo && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Demo Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row>
                      <Col span={15}>
                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={6}>
                            <FaRegCalendarAlt size={17} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.demo.date}</Text>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={6}>
                            <FaRegClock size={18} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.demo.time}</Text>
                          </Col>
                        </Row>

                        {selectedResponse.demo.platform && (
                          <Row
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <Col span={6}>
                              <Text strong> Platform</Text>
                            </Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>
                              {channels[
                                selectedResponse.demo.platform.toUpperCase()
                              ] ? (
                                <Image
                                  src={
                                    channels[
                                      selectedResponse.demo.platform.toUpperCase()
                                    ].logo
                                  }
                                  width={23}
                                  preview={false}
                                  style={{
                                    marginLeft: 12,
                                  }}
                                />
                              ) : (
                                <Text>{selectedResponse.demo.platform}</Text>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <Col span={8}>
                        {selectedResponse.demo.duration && (
                          <Row
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <Col span={5}>
                              <ImStopwatch size={19} />
                            </Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>
                              <Text> {selectedResponse.demo.duration}</Text>
                            </Col>
                          </Row>
                        )}

                        {selectedResponse.demo.status && (
                          <>
                            <Col span={24}>
                              <Text strong>Status </Text>:
                            </Col>

                            <Col span={24}>
                              <Badge
                                status={
                                  selectedResponse.demo.status === "Confirmed"
                                    ? "success"
                                    : "default"
                                }
                                text={selectedResponse.demo.status}
                              />
                            </Col>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </>
              )}
            </div>
          );

        case "Feedback":
          return (
            <div>
              {(() => {
                switch (selectedResponse.subCategory) {
                  case "Education Feedback":
                    return (
                      <>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text strong>
                            {selectedResponse.typeOfUser || "User"} Details
                          </Text>
                          <Text>
                            {getUserDateTime(
                              selectedResponse.responseDate,
                              state.currentUser.user.timeZone,
                              state.currentUser.user.dateFormat
                            )}
                          </Text>
                        </Row>

                        <Card
                          style={{
                            marginTop: 9,
                          }}
                          size="small"
                        >
                          <Row gutter={[0, 4]}>
                            <Col span={15}>
                              <Text
                                ellipsis
                                style={{
                                  color: "rgb(0 0 0)",
                                  fontSize: 15,
                                }}
                              >
                                {selectedResponse.userProfileName}
                              </Text>
                            </Col>

                            {selectedResponse.relation && (
                              <Col span={9}>
                                <Row>
                                  <Col span={10}>
                                    <Text>Relation: </Text>
                                  </Col>
                                  <Col
                                    span={14}
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Tag
                                      color="#eeeef0"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Text>{selectedResponse.relation}</Text>
                                    </Tag>
                                  </Col>
                                </Row>
                              </Col>
                            )}

                            {selectedResponse.childName && (
                              <Col span={24}>
                                <Row>
                                  <Col span={4}>
                                    <Text>Child: </Text>
                                  </Col>
                                  <Col
                                    span={17}
                                    style={{
                                      textAlign: "start",
                                    }}
                                  >
                                    <Tag
                                      color="#eeeef0"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Text>{selectedResponse.childName}</Text>
                                    </Tag>
                                  </Col>
                                </Row>
                              </Col>
                            )}

                            {selectedResponse.userPhoneNumber && (
                              <Col span={24}>
                                <Text>
                                  {formatPhoneNumber(
                                    selectedResponse.userPhoneNumber.startsWith(
                                      "+"
                                    )
                                      ? selectedResponse.userPhoneNumber
                                      : `+${selectedResponse.userPhoneNumber}`
                                  )}
                                </Text>
                              </Col>
                            )}

                            {selectedResponse.userEmail && (
                              <Col span={24}>
                                <Text> {selectedResponse.userEmail}</Text>
                              </Col>
                            )}

                            {selectedResponse.school && (
                              <Col span={15}>
                                <Row>
                                  <Col span={6}>
                                    <Text>School: </Text>
                                  </Col>
                                  <Col
                                    span={17}
                                    style={{
                                      textAlign: "start",
                                    }}
                                  >
                                    <Tag
                                      color="#eeeef0"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Text>{selectedResponse.school}</Text>
                                    </Tag>
                                  </Col>
                                </Row>
                              </Col>
                            )}

                            {selectedResponse.standard && (
                              <Col
                                span={9}
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <Row>
                                  <Col span={10}>
                                    <Text>Standard: </Text>
                                  </Col>
                                  <Col
                                    span={14}
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Tag
                                      color="#eeeef0"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <Text>{selectedResponse.standard}</Text>
                                    </Tag>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Card>

                        <Space
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Text strong>Feedback Questions</Text>
                        </Space>
                      </>
                    );
                  default:
                    return (
                      <>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text strong>Customer Details</Text>
                          <Text>
                            {getUserDateTime(
                              selectedResponse.responseDate,
                              state.currentUser.user.timeZone,
                              state.currentUser.user.dateFormat
                            )}
                          </Text>
                        </Row>

                        <Card
                          style={{
                            marginTop: 9,
                          }}
                          size="small"
                        >
                          <Row>
                            <Col span={15}>
                              <Row gutter={[0, 4]}>
                                <Col span={24}>
                                  <Text
                                    ellipsis
                                    style={{
                                      color: "rgb(0 0 0)",
                                      fontSize: 15,
                                    }}
                                  >
                                    {selectedResponse.userProfileName}
                                  </Text>
                                </Col>

                                {selectedResponse.userPhoneNumber && (
                                  <Col span={24}>
                                    <Text>
                                      {formatPhoneNumber(
                                        selectedResponse.userPhoneNumber.startsWith(
                                          "+"
                                        )
                                          ? selectedResponse.userPhoneNumber
                                          : `+${selectedResponse.userPhoneNumber}`
                                      )}
                                    </Text>
                                  </Col>
                                )}

                                {selectedResponse.userEmail && (
                                  <Col span={24}>
                                    <Text> {selectedResponse.userEmail}</Text>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Card>

                        <Space
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Text strong>Survey Questions</Text>
                        </Space>
                      </>
                    );
                }
              })()}

              <List
                dataSource={selectedResponse.surveyQuestions}
                renderItem={(surveyQuestion, index) => (
                  <Card
                    key={index}
                    style={{
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                    size="small"
                  >
                    <Card.Meta
                      title={
                        <Tooltip title={surveyQuestion.question}>
                          <span>
                            {state.application.enums?.responses?.data?.find(
                              ({ _id }) => _id === surveyQuestion.question
                            )?.display_name || surveyQuestion.question}
                          </span>
                        </Tooltip>
                      }
                      description={
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {typeof surveyQuestion.answer === "number" ? (
                            <Rate disabled value={surveyQuestion.answer} />
                          ) : (
                            <Text
                              style={{
                                color: "#000000",
                                fontSize: 16,
                              }}
                            >
                              {surveyQuestion.answer}
                            </Text>
                          )}
                        </Row>
                      }
                    />
                  </Card>
                )}
              />
            </div>
          );

        case "Ticket Booking":
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Customer Details</Text>
                <Text>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={15}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Space
                style={{
                  marginTop: "10px",
                }}
              >
                <Text strong>Ticket Details</Text>
              </Space>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Text strong>
                      {selectedResponse.ticketDetails.placeName}
                    </Text>
                  </Col>
                  {selectedResponse.ticketDetails?.date && (
                    <Col
                      span={24}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Text>Date : </Text>
                      <Tooltip title={selectedResponse.ticketDetails.date}>
                        <Text ellipsis>
                          {selectedResponse.ticketDetails.date}
                        </Text>
                      </Tooltip>
                    </Col>
                  )}

                  {selectedResponse.ticketDetails.adult && (
                    <>
                      <Col span={4}>
                        <Text
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          Adult
                        </Text>
                      </Col>

                      <Col span={2}>
                        <CloseOutlined
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 10,
                          }}
                        />
                      </Col>

                      <Col span={5}>
                        <Text>
                          {selectedResponse.ticketDetails.adult.count}
                        </Text>
                      </Col>

                      <Col span={8}>
                        <Text strong>
                          {selectedResponse.ticketDetails.adult.currency}{" "}
                          {selectedResponse.ticketDetails.adult.totalAdultPrice}
                        </Text>
                      </Col>

                      <Col span={3}></Col>
                    </>
                  )}

                  {selectedResponse.ticketDetails.child && (
                    <>
                      <Col span={4}>
                        <Text
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          Child
                        </Text>
                      </Col>

                      <Col span={2}>
                        <CloseOutlined
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 10,
                          }}
                        />
                      </Col>

                      <Col span={5}>
                        {selectedResponse.ticketDetails.child.count}
                      </Col>

                      <Col span={8}>
                        <Text strong>
                          {selectedResponse.ticketDetails.child.currency}{" "}
                          {selectedResponse.ticketDetails.child.totalChildPrice}
                        </Text>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>

              <Row style={{ marginTop: 10 }}>
                <Col
                  span={8}
                  style={{
                    textAlign: "Right",
                  }}
                >
                  <Text strong>Total Price </Text>
                </Col>
                <Col
                  span={2}
                  style={{
                    textAlign: "Right",
                  }}
                >
                  <Text strong> :</Text>
                </Col>
                <Col
                  span={5}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Text strong>
                    {selectedResponse.ticketDetails.price.currency}{" "}
                    {selectedResponse.ticketDetails.price.totalPrice}
                  </Text>
                </Col>
              </Row>
            </div>
          );

        case "Leave Application":
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Employee Details</Text>
                <Text>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={15}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {selectedResponse.employeeId && (
                    <Col span={9}>
                      <Space>
                        <Text ellipsis>Employee ID: </Text>
                        <Text ellipsis>{selectedResponse.employeeId}</Text>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Card>

              <Space
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Leave Details</Text>

                {selectedResponse.submissionDate && (
                  <Text>
                    {getUserDateTime(
                      selectedResponse.submissionDate,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                )}
              </Space>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row gutter={[10, 15]}>
                  {selectedResponse.startDate && (
                    <Col span={12}>
                      <Row>
                        <Col span={5}>
                          <Text>From:</Text>
                        </Col>
                        <Col span={12}>
                          <Tag
                            color="#eeeef0"
                            style={{
                              width: "80%",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <Text> {selectedResponse.startDate}</Text>
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {selectedResponse.totalDays && (
                    <Col
                      span={12}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Space>
                        <Text>Total Days:</Text>

                        <Tag
                          color="#eeeef0"
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                        >
                          <Text> {selectedResponse.totalDays}</Text>
                        </Tag>
                      </Space>
                    </Col>
                  )}

                  {selectedResponse.endDate && (
                    <Col span={12}>
                      <Row>
                        <Col span={5}>
                          <Text>To:</Text>
                        </Col>
                        <Col span={12}>
                          <Tag
                            color="#eeeef0"
                            style={{
                              width: "80%",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <Text> {selectedResponse.endDate}</Text>
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {selectedResponse.leaveType && (
                    <Col span={24}>
                      <Row>
                        <Col span={5}>
                          <Text>Leave Type:</Text>
                        </Col>
                        <Col span={19}>
                          <Text> {selectedResponse.leaveType}</Text>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {selectedResponse.reason && (
                    <Col span={24}>
                      <Row>
                        <Col span={5}>
                          <Text>Reason:</Text>
                        </Col>
                        <Col span={19}>
                          <Text> {selectedResponse.reason}</Text>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {selectedResponse.comments && (
                    <Col span={24}>
                      <Row>
                        <Col span={5}>
                          <Text>Comments:</Text>
                        </Col>
                        <Col span={19}>
                          <Text> {selectedResponse.comments}</Text>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Card>

              {(selectedResponse.approver || selectedResponse.status) && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Approval Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row gutter={[10, 15]}>
                      {selectedResponse.approver && (
                        <Col span={12}>
                          <Row>
                            <Col span={10}>
                              <Text>Approver:</Text>
                            </Col>
                            <Col span={12}>
                              <Text> {selectedResponse.approver}</Text>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {selectedResponse.status && (
                        <Col
                          span={12}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Space>
                            <Text>Status:</Text>

                            <Badge
                              status={
                                selectedResponse.status.toUpperCase() ===
                                "PENDING"
                                  ? "processing"
                                  : selectedResponse.status.toUpperCase() ===
                                    "APPROVED"
                                  ? "success"
                                  : selectedResponse.status.toUpperCase() ===
                                    "REJECTED"
                                  ? "error"
                                  : "default"
                              }
                              text={selectedResponse.status}
                            />
                          </Space>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </>
              )}
            </div>
          );

        case "Claim Application":
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Employee Details</Text>
                <Text>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={14}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {selectedResponse.employeeId && (
                    <Col
                      span={10}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Space>
                        <Text>Employee ID: </Text>
                        <Text ellipsis>{selectedResponse.employeeId}</Text>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Card>

              <Space
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Claim Details</Text>

                {selectedResponse.submissionDate && (
                  <Text>
                    {getUserDateTime(
                      selectedResponse.submissionDate,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                )}
              </Space>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Collapse
                  defaultActiveKey={
                    selectedResponse.claims?.map((claim) => `${claim._id}`)[0]
                  }
                >
                  {selectedResponse.claims?.map((claim) => (
                    <Panel header={claim.description} key={claim._id}>
                      <Row gutter={[10, 15]}>
                        {claim.type && (
                          <Col span={19}>
                            <Row>
                              <Col span={4}>
                                <Text>Type:</Text>
                              </Col>
                              <Col span={20}>
                                <Text> {claim.type}</Text>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {claim.amount && (
                          <Col span={5}>
                            <Text ellipsis>{claim.date}</Text>
                          </Col>
                        )}

                        {claim.amount && (
                          <Col span={12}>
                            <Row>
                              <Col span={10}>
                                <Text>Amount:</Text>
                              </Col>
                              <Col span={12}>
                                <Tag
                                  color="#eeeef0"
                                  style={{
                                    width: "90%",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Text>
                                    {claim.currency} {claim.amount}
                                  </Text>
                                </Tag>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {claim.merchantName && (
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Text>Merchant Name:</Text>
                              </Col>
                              <Col span={16}>
                                <Text> {claim.merchantName}</Text>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {claim.receiptNumber && (
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Text>Receipt Number:</Text>
                              </Col>
                              <Col span={16}>
                                <Text> {claim.receiptNumber}</Text>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {claim.comments && (
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Text>Comments:</Text>
                              </Col>
                              <Col span={16}>
                                <Text> {claim.comments}</Text>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {(claim.approver || claim.status) && (
                          <>
                            <Col span={24}>
                              <Text strong>Approval Details</Text>
                            </Col>

                            {claim.approver && (
                              <Col span={12}>
                                <Row>
                                  <Col span={10}>
                                    <Text>Approver:</Text>
                                  </Col>
                                  <Col span={12}>
                                    <Text> {claim.approver}</Text>
                                  </Col>
                                </Row>
                              </Col>
                            )}

                            {claim.status && (
                              <Col
                                span={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Space>
                                  <Text>Status:</Text>

                                  <Badge
                                    status={
                                      claim.status.toUpperCase() === "PENDING"
                                        ? "processing"
                                        : claim.status.toUpperCase() ===
                                          "APPROVED"
                                        ? "success"
                                        : claim.status.toUpperCase() ===
                                          "REJECTED"
                                        ? "error"
                                        : "default"
                                    }
                                    text={claim.status}
                                  />
                                </Space>
                              </Col>
                            )}
                          </>
                        )}

                        {claim.documentLink && (
                          <Space
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #eeeef0",
                              padding: "5px",
                              width: "90%",
                              borderRadius: "5px",
                              backgroundColor: "#eeeef0",
                              height: "50px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const link = document.createElement("a");
                              link.target = "_blank";
                              link.download =
                                claim.documentLink.split("/")[
                                  claim.documentLink.split("/").length - 1
                                ];
                              link.href = claim.documentLink;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            <Image
                              src={
                                fileIcon[
                                  claim.documentLink
                                    ?.split(".")
                                    .pop()
                                    .toLowerCase()
                                ] || "/images/document-placeholder.png"
                              }
                              height={30}
                              preview={false}
                            />
                            {(() => {
                              const url = claim.documentLink;

                              const fileName = url
                                .split("/")
                                .pop()
                                .split("_")
                                .slice(2) // Skip the first two parts ("uca" and "claim")
                                .join("_"); // Rejoin the parts with underscore

                              const decodedFileName =
                                decodeURIComponent(fileName);

                              return decodedFileName;
                            })()}
                          </Space>
                        )}
                      </Row>
                    </Panel>
                  ))}
                </Collapse>
              </Card>
            </div>
          );

        case "Distributor/Dealer Enquiry":
        case "Purchase Enquiry":
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Customer Details</Text>
                <Text>
                  {getUserDateTime(
                    selectedResponse.responseDate,
                    state.currentUser.user.timeZone,
                    state.currentUser.user.dateFormat
                  )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={14}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}

                      {selectedResponse.alternatePhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.alternatePhoneNumber.startsWith(
                                "+"
                              )
                                ? selectedResponse.alternatePhoneNumber
                                : `+${selectedResponse.alternatePhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}

                      {selectedResponse.userEmail && (
                        <Col span={24}>
                          <Text ellipsis>{selectedResponse.userEmail}</Text>
                        </Col>
                      )}

                      {selectedResponse.companyName && (
                        <Col span={24}>
                          <Text>{selectedResponse.companyName}</Text>
                        </Col>
                      )}

                      {selectedResponse.location && (
                        <Col span={24}>
                          <Text>{selectedResponse.location}</Text>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {selectedResponse.enquiryDate && (
                    <Col
                      span={10}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Space>
                        <Text ellipsis>
                          {getUserDateTime(
                            selectedResponse.enquiryDate,
                            state.currentUser.user.timeZone,
                            state.currentUser.user.dateFormat
                          )}
                        </Text>
                      </Space>
                    </Col>
                  )}
                </Row>
              </Card>

              {selectedResponse.productsOfInterest && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text
                      strong
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Products of Interest
                    </Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Space direction="vertical">
                      {selectedResponse.productsOfInterest?.map(
                        (product, index) => (
                          <Tag
                            key={index}
                            color="#eeeef0"
                            style={{
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <Text> {product}</Text>
                          </Tag>
                        )
                      )}
                    </Space>
                  </Card>
                </>
              )}

              {selectedResponse.products && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text
                      strong
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Product Details
                    </Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Collapse
                      defaultActiveKey={
                        selectedResponse.products?.map(
                          (product) => `${product._id}`
                        )[0]
                      }
                    >
                      {selectedResponse.products?.map((product) => (
                        <Panel header={product.name} key={product._id}>
                          <Row gutter={[0, 10]}>
                            {Object.entries(product)
                              .filter(([key]) => key !== "_id")
                              .map(([key, value]) => (
                                <Col span={24} key={key}>
                                  <Text strong>
                                    {key
                                      .replace(/([A-Z][a-z])/g, " $1")
                                      .replace(/^./, (str) =>
                                        str.toUpperCase()
                                      )}{" "}
                                    :{" "}
                                  </Text>
                                  {Array.isArray(value) ||
                                  typeof value === "object"
                                    ? JSON.stringify(value)
                                    : value}
                                </Col>
                              ))}
                          </Row>
                        </Panel>
                      ))}
                    </Collapse>
                  </Card>
                </>
              )}
            </div>
          );

        default:
          return (
            <div>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text strong>Customer Details</Text>
                <Text>
                  {selectedResponse.responseDate &&
                    getUserDateTime(
                      selectedResponse.responseDate,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                </Text>
              </Row>

              <Card
                style={{
                  marginTop: 9,
                }}
                size="small"
              >
                <Row>
                  <Col span={15}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Text
                          ellipsis
                          style={{
                            color: "rgb(0 0 0)",
                            fontSize: 15,
                          }}
                        >
                          {selectedResponse.userProfileName}
                        </Text>
                      </Col>

                      {selectedResponse.userPhoneNumber && (
                        <Col span={24}>
                          <Text>
                            {formatPhoneNumber(
                              selectedResponse.userPhoneNumber.startsWith("+")
                                ? selectedResponse.userPhoneNumber
                                : `+${selectedResponse.userPhoneNumber}`
                            )}
                          </Text>
                        </Col>
                      )}

                      {selectedResponse.userEmail && (
                        <Col span={24}>
                          <Text> {selectedResponse.userEmail}</Text>
                        </Col>
                      )}

                      {selectedResponse.userAddress && (
                        <Col span={24}>
                          <Text>{selectedResponse.userAddress}</Text>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {selectedResponse.company ||
                    (selectedResponse.companyName && (
                      <Col span={9}>
                        <Col
                          span={24}
                          style={{
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <Text>Company: </Text>
                          <Text
                            ellipsis
                            style={{
                              color: "rgb(0 0 0)",
                              fontSize: 15,
                            }}
                          >
                            {selectedResponse.company ||
                              selectedResponse.companyName}
                          </Text>
                        </Col>
                      </Col>
                    ))}
                </Row>
              </Card>

              {selectedResponse.demo && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Demo Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row>
                      <Col span={15}>
                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={6}>
                            <FaRegCalendarAlt size={17} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.demo.date}</Text>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            marginBottom: 5,
                          }}
                        >
                          <Col span={6}>
                            <FaRegClock size={18} />
                          </Col>
                          <Col span={2}>:</Col>
                          <Col span={14}>
                            <Text> {selectedResponse.demo.time}</Text>
                          </Col>
                        </Row>

                        {selectedResponse.demo.platform && (
                          <Row
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <Col span={6}>
                              <Text strong> Platform</Text>
                            </Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>
                              {channels[
                                selectedResponse.demo.platform.toUpperCase()
                              ] ? (
                                <Image
                                  src={
                                    channels[
                                      selectedResponse.demo.platform.toUpperCase()
                                    ].logo
                                  }
                                  width={23}
                                  preview={false}
                                  style={{
                                    marginLeft: 12,
                                  }}
                                />
                              ) : (
                                <Text>{selectedResponse.demo.platform}</Text>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <Col span={8}>
                        {selectedResponse.demo.duration && (
                          <Row
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            <Col span={5}>
                              <ImStopwatch size={19} />
                            </Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>
                              <Text> {selectedResponse.demo.duration}</Text>
                            </Col>
                          </Row>
                        )}

                        {selectedResponse.demo.status && (
                          <>
                            <Col span={24}>
                              <Text strong>Status </Text>:
                            </Col>

                            <Col span={24}>
                              <Badge
                                status={
                                  selectedResponse.demo.status === "Confirmed"
                                    ? "success"
                                    : "default"
                                }
                                text={selectedResponse.demo.status}
                              />
                            </Col>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </>
              )}

              {selectedResponse.programDetails && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Program Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <List
                      dataSource={Object.entries(
                        selectedResponse.programDetails
                      )?.map(([key, value]) => ({
                        key: key
                          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase
                          .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word
                        value,
                      }))}
                      renderItem={({ key, value }) => (
                        <List.Item
                          key={key}
                          style={{
                            padding: 5,
                            margin: 0,
                          }}
                        >
                          <Text strong>{key}:</Text>{" "}
                          {typeof value !== "number" && moment(value).isValid()
                            ? getUserDateTime(
                                value,
                                state.currentUser.user.timeZone,
                                state.currentUser.user.dateFormat
                              )
                            : value}
                        </List.Item>
                      )}
                      style={{
                        padding: 0,
                        borderRadius: 10,
                      }}
                    />
                  </Card>

                  {selectedResponse.source && (
                    <>
                      <Space
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <Text strong>Source</Text>
                      </Space>

                      <Card
                        style={{
                          marginTop: 9,
                        }}
                        size="small"
                      >
                        <Text>
                          How did you hear about us? : {selectedResponse.source}
                        </Text>
                      </Card>
                    </>
                  )}
                </>
              )}

              {selectedResponse?.communicationTypes?.length > 0 && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Communication Types</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <List
                      dataSource={selectedResponse.communicationTypes}
                      renderItem={(communicationType, index) => (
                        <List.Item
                          key={index}
                          style={{
                            padding: 5,
                            margin: 0,
                          }}
                        >
                          <Text>{index + 1 + ". " + communicationType}</Text>
                        </List.Item>
                      )}
                      style={{
                        padding: 0,
                        borderRadius: 10,
                      }}
                    />
                  </Card>
                </>
              )}

              {selectedResponse.contactPreference && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Contact Preference</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Text>{selectedResponse.contactPreference}</Text>
                  </Card>
                </>
              )}

              {selectedResponse.notes && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Notes</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Text>{selectedResponse.notes}</Text>
                  </Card>
                </>
              )}

              {selectedResponse.category === "Support Ticket" && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Issue Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row gutter={[10, 10]}>
                      {selectedResponse.issueTitle && (
                        <Col span={24}>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text strong>{selectedResponse.issueTitle}</Text>
                            {selectedResponse.ticketId && (
                              <Text>Id:{" " + selectedResponse.ticketId}</Text>
                            )}
                          </Space>
                        </Col>
                      )}

                      {selectedResponse.issueDescription && (
                        <Col span={24}>
                          <Row>
                            <Col span={5}>Description:</Col>
                            <Col span={19}>
                              <Text>{selectedResponse.issueDescription}</Text>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {selectedResponse.priority && (
                        <Col span={24}>
                          <Row>
                            <Col span={5}>Priority:</Col>
                            <Col span={19}>
                              <Text>
                                {selectedResponse.priority.toLowerCase() ===
                                "high" ? (
                                  <Tag color="red">
                                    {selectedResponse.priority}
                                  </Tag>
                                ) : selectedResponse.priority.toLowerCase() ===
                                  "medium" ? (
                                  <Tag color="orange">
                                    {selectedResponse.priority}
                                  </Tag>
                                ) : (
                                  <Tag color="green">
                                    {selectedResponse.priority}
                                  </Tag>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </>
              )}

              {selectedResponse.category === "Incident Report" && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Incident Details</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Row gutter={[10, 10]}>
                      {selectedResponse.incidentType && (
                        <Col span={24}>
                          <Row>
                            <Col span={6}>Incident Type:</Col>
                            <Col span={18}>
                              <Text>{selectedResponse.incidentType}</Text>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {selectedResponse.severityLevel && (
                        <Col span={24}>
                          <Row>
                            <Col span={6}>Severity Level:</Col>
                            <Col span={18}>
                              <Text>
                                {selectedResponse.severityLevel.toLowerCase() ===
                                "high" ? (
                                  <Tag color="red">
                                    {selectedResponse.severityLevel}
                                  </Tag>
                                ) : selectedResponse.severityLevel.toLowerCase() ===
                                  "medium" ? (
                                  <Tag color="orange">
                                    {selectedResponse.severityLevel}
                                  </Tag>
                                ) : (
                                  <Tag color="green">
                                    {selectedResponse.severityLevel}
                                  </Tag>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {selectedResponse.violationType && (
                        <Col span={24}>
                          <Row>
                            <Col span={6}>Violation Type:</Col>
                            <Col span={18}>
                              <Text>{selectedResponse.violationType}</Text>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {selectedResponse.comments && (
                        <Col span={24}>
                          <Row>
                            <Col span={6}>Comments:</Col>
                            <Col span={18}>
                              <Text>{selectedResponse.comments}</Text>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </>
              )}

              {selectedResponse.tags?.length !== 0 && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Tags</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Space
                      style={{
                        flexWrap: "wrap",
                      }}
                      size={0}
                    >
                      {selectedResponse.tags?.map((tag) => (
                        <Tag key={tag} color="blue">
                          {tag}
                        </Tag>
                      ))}
                    </Space>
                  </Card>
                </>
              )}

              {selectedResponse?.solutions &&
                (selectedResponse.solutions.split(",").length > 1 ? (
                  <>
                    <Space
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Text strong>Solutions</Text>
                    </Space>

                    <Card
                      style={{
                        marginTop: 9,
                      }}
                      size="small"
                    >
                      <List
                        dataSource={selectedResponse.solutions.split(",")}
                        renderItem={(solution, index) => (
                          <List.Item
                            key={index}
                            style={{
                              padding: 5,
                              margin: 0,
                            }}
                          >
                            <Text>{index + 1 + ". " + solution}</Text>
                          </List.Item>
                        )}
                        style={{
                          padding: 0,
                          borderRadius: 10,
                        }}
                      />
                    </Card>
                  </>
                ) : null)}

              {selectedResponse.preferredLanguage && (
                <>
                  <Space
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Text strong>Preferred Language</Text>
                  </Space>

                  <Card
                    style={{
                      marginTop: 9,
                    }}
                    size="small"
                  >
                    <Text>{selectedResponse.preferredLanguage}</Text>
                  </Card>
                </>
              )}
            </div>
          );
      }
    }
    return (
      <ConversationInfoPanel isContactAlreadyExist={isContactAlreadyExist} />
    );
  };

  // Check current bot is notify bot or not
  const isNotifyBot =
    state.currentAccount.account.bots
      .find((bot) => bot.bot_id === state.application.botInAction)
      ?.bot_version?.toUpperCase() === "V0.1";

  return (
    <Drawer
      title={
        <Row>
          <Col span={14}>
            <Text>
              {currentResponseCategory
                ? (currentResponseCategory + " Details").length > 23
                  ? currentResponseCategory.substring(0, 23) + "..."
                  : currentResponseCategory + " Details"
                : null}{" "}
            </Text>

            {selectedResponse.channel && (
              <Image
                src={channels[selectedResponse.channel.toUpperCase()]?.logo}
                width={23}
                preview={false}
                style={{
                  marginLeft: 12,
                }}
              />
            )}
          </Col>

          <Col span={10}>
            <Space
              size={17}
              style={{
                float: "right",
              }}
            >
              {currentResponseCategory?.toLowerCase().includes("order") && (
                <Tooltip title="Print">
                  <BiPrinter
                    style={{
                      fontSize: 20,
                      color: "#F05742",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrintInvoicePanel(true);
                      setTimeout(() => {
                        handlePrintInvoice();
                      }, 50);
                      appInsights.trackEvent({
                        name: "Responses - Print Invoice",
                        properties: {
                          clientId: state.currentAccount.account.client_id,
                          environment: mode,
                        },
                      });
                    }}
                  />
                </Tooltip>
              )}

              {!isContactAlreadyExist ? (
                <Tooltip
                  title={
                    state.currentUser.permission.CONTACT_CREATE
                      ? "Add to Contact"
                      : "You do not have required permission"
                  }
                  placement="topLeft"
                >
                  {state.currentUser.permission.CONTACT_CREATE ? (
                    <BiUserPlus
                      style={{
                        fontSize: 22,
                        color: "#F05742",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatchReducer({
                          type: "OPEN_FORM_PANEL",
                          payload: {
                            component: "contact-manager-form-panel",
                            title: "Associate Contact",
                          },
                        });
                        dispatchReducer({
                          type: "SET_FORM_MODE",
                          payload: {
                            component: "contact-manager-form",
                            mode: "add",
                          },
                        });
                        dispatchMiddleware({
                          type: "MWD_LOAD_CONTACT_MANAGER_DATA",
                          payload: { component: "contact-manager-table" },
                        });
                      }}
                    />
                  ) : (
                    <BiUserPlus style={{ color: "#C0C0C0", fontSize: 22 }} />
                  )}
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    state.infoPanel["conversation-info-panel"]?.visible
                      ? "Close Contact"
                      : "View Contact"
                  }
                  placement="topLeft"
                >
                  <AiOutlineContacts
                    style={{
                      fontSize: 20,
                      color: "#F05742",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      !state.infoPanel["conversation-info-panel"]?.visible
                        ? dispatchReducer({
                            type: "OPEN_INFO_PANEL",
                            payload: {
                              component: "conversation-info-panel",
                            },
                          })
                        : dispatchReducer({
                            type: "CLOSE_INFO_PANEL",
                            payload: {
                              component: "conversation-info-panel",
                            },
                          });
                    }}
                  />
                </Tooltip>
              )}
              {!isNotifyBot && (
                <Tooltip
                  placement="topLeft"
                  title={
                    state.currentUser.permission.RESPONSE_VIEW_CONVERSATION
                      ? "View Conversation"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.RESPONSE_VIEW_CONVERSATION ? (
                    <BiConversation
                      style={{
                        fontSize: 20,
                        marginTop: 3,
                        color: "#F05742",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const selectedResponse =
                          state.clientResponses.responses.data.find(
                            (response) =>
                              response._id ===
                              state.filters.actionRowKey[
                                "client-responses-table"
                              ]
                          );

                        dispatchReducer({
                          type: "RD_SET_CONVERSATION_FILTER",
                          payload: {
                            conversationFilter: true,
                          },
                        });

                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component:
                              "client-responses-view-conversation-info-panel",
                          },
                        });

                        // Clear existing conversation
                        if (state.conversations.contactsWithTranscripts) {
                          dispatchReducer({
                            type: "RD_CLEAR_CONVERSATIONS_CONTACT_WITH_TRANSCRIPTS",
                          });
                          dispatchReducer({
                            type: "RD_CLEAR_CURRENT_CONVERSATION",
                          });
                        }

                        // Load selected conversation based on response
                        dispatchMiddleware({
                          type: "MWD_LOAD_SINGLE_CONVERSATION_CONTACT_WITH_TRANSCRIPTS",
                          payload: {
                            conversationId:
                              selectedResponse.conversationId.replace("+", ""),
                            botId:
                              selectedResponse.botId || selectedResponse.bot_id,
                            component: "conversations-layout",
                            contactId: (
                              selectedResponse.conversationId +
                              "_" +
                              selectedResponse.botId
                            ).replace("+", ""),
                            leadDate:
                              selectedResponse.leadDate ||
                              selectedResponse.responseDate,
                            category: selectedResponse.category,
                          },
                        });

                        // Close info panel
                        dispatchReducer({
                          type: "CLOSE_INFO_PANEL",
                          payload: {
                            component: "client-response-info-panel",
                          },
                        });
                      }}
                    />
                  ) : (
                    <BiConversation style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              )}

              {state.currentAccount.account.modules.broadcast.enabled &&
                currentResponse?.channel.toUpperCase() === "WHATSAPP" && (
                  <Tooltip
                    placement="topLeft"
                    title={
                      !state.currentUser.permission.BROADCAST_CREATE
                        ? "You do not have required permission"
                        : parsePhoneNumber(
                            currentResponse.conversationId
                          )?.isValid()
                        ? "Create a new broadcast"
                        : "Invalid phone number"
                    }
                  >
                    {state.currentUser.permission.BROADCAST_CREATE &&
                    // Check if the phone number is valid
                    parsePhoneNumber(
                      currentResponse.conversationId
                    )?.isValid() ? (
                      <BiBroadcast
                        style={{
                          color: "#F05742",
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // Navigate to broadcast view
                          navigate("/broadcast-manager");
                          sessionStorage.setItem(
                            "selectedMenu",
                            "/broadcast-manager"
                          );

                          dispatchReducer({
                            type: "OPEN_FORM_PANEL",
                            payload: {
                              component: "broadcast-form-panel",
                              title: "Create New Broadcast",
                            },
                          });

                          const phoneNumber = parsePhoneNumber(
                            currentResponse.conversationId
                          );

                          // Split the phone number and country code
                          if (phoneNumber) {
                            dispatchReducer({
                              type: "SET_SINGLE_CONTACT_TO_POPULATE",
                              payload: {
                                contact: {
                                  displayName: currentResponse.userProfileName,
                                  countryCode: phoneNumber.countryCallingCode,
                                  phoneNumberWithoutCountryCode:
                                    phoneNumber.nationalNumber,
                                },
                              },
                            });
                          }

                          dispatchReducer({
                            type: "CLOSE_INFO_PANEL",
                            payload: {
                              component: "client-response-info-panel",
                            },
                          });
                        }}
                      />
                    ) : (
                      <BiBroadcast
                        style={{
                          color: "#C0C0C0",
                          fontSize: 20,
                        }}
                      />
                    )}
                  </Tooltip>
                )}

              {/*Response Timeline */}
              {
                <Tooltip
                  title={
                    permission.RESPONSES_TIMELINE_VIEW
                      ? !responsesTimeline
                        ? translation.responseTimeline.showInfoText
                        : translation.responseTimeline.hideInfoText
                      : translation.noAccess
                  }
                  placement="topLeft"
                >
                  {responsesTimeline ? (
                    <MdHistoryToggleOff
                      style={{
                        fontSize: 20,
                        color: "#F05742",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        dispatchReducer({
                          type: "CLEAR_RESPONSES_TIMELINE",
                        })
                      }
                    />
                  ) : (
                    <MdHistory
                      style={{
                        fontSize: 20,
                        color: permission.RESPONSES_TIMELINE_VIEW
                          ? "#F05742"
                          : "#C0C0C0",
                        cursor: permission.RESPONSES_TIMELINE_VIEW
                          ? "pointer"
                          : "",
                      }}
                      onClick={() =>
                        permission.RESPONSES_TIMELINE_VIEW &&
                        dispatchMiddleware({
                          type: "LOAD_RESPONSES_TIMELINE",
                          payload: {
                            component: "client-responses-timeline-list",
                          },
                        })
                      }
                    />
                  )}
                </Tooltip>
              }
            </Space>
          </Col>
        </Row>
      }
      open={
        state.infoPanel["client-response-info-panel"] &&
        state.infoPanel["client-response-info-panel"].visible
      }
      onClose={() => {
        dispatchReducer({
          type: "CLEAR_RESPONSES_TIMELINE",
        });
        dispatchReducer({
          type: "RD_CLEAR_ACTION_ROW_KEY",
          payload: { component: "client-responses-table" },
        });
        dispatchReducer({
          type: "CLEAR_CURRENT_RESPONSE",
        });
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "client-response-info-panel",
          },
        });
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "conversation-info-panel",
          },
        });
        showPrintInvoicePanel && setShowPrintInvoicePanel(false);
      }}
      bodyStyle={{ paddingBottom: 80 }}
      width={490}
    >
      {state.apiCallStatus["client-responses-timeline-list"] &&
      state.apiCallStatus["client-responses-timeline-list"].loading ? (
        <TabLoader
          component="client-responses-timeline-list"
          loadingMessage="Loading... Please wait..."
          errorMessage="Error loading responses timeline. Please try again"
        />
      ) : responsesTimeline ? (
        <Space
          size={10}
          style={{
            width: "90%",
          }}
          direction="vertical"
        >
          <Space>User Responses Timeline</Space>

          <Timeline>
            {responsesTimeline?.map((response, index) => {
              return (
                <Item key={index} dot={response.dot}>
                  {index !== 0 && (
                    <Space direction="vertical" size={2}>
                      <Text strong>{response.category}</Text>
                      <Text type="secondary">
                        ({" "}
                        {getUserDateTime(
                          response.responseDate,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}{" "}
                        )
                      </Text>
                    </Space>
                  )}
                </Item>
              );
            })}
          </Timeline>
        </Space>
      ) : showPrintInvoicePanel ? (
        <PrintInvoicePanel selectedResponse={selectedResponse} />
      ) : (
        <>
          {renderInfoPanel(selectedResponse)}

          {console.log("selectedResponse", selectedResponse)}

          {currentResponseCategory?.toLowerCase().includes("order") && (
            <>
              <Divider />
              <DeliveryServicesPanel selectedResponse={selectedResponse} />
            </>
          )}
        </>
      )}

      <ContactManagerFormPanel type="associate_contact" />

      {/* Create reference to print */}
      <iframe
        src="about:blank"
        style={{ display: "none" }}
        title="Print Frame"
        ref={printFrameRef}
      />
    </Drawer>
  );
};

export default ClientResponsesInfoPanelV2;
