import React from "react";
import {
  Space,
  Row,
  Col,
  Image,
  Typography,
  Button,
  Modal,
  Tooltip,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { channels } from "../../constants/enums";
import { useGlobalContext } from "../../contexts/globalContext";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { wabaProvider } from "../../constants/enums";
import { DirectLine } from "botframework-directlinejs";
import openNotificationWithIcon from "../../utils/notification";
import { getCookieValue, setCookie } from "../../utils/utility";
import ConversationSummaryPanel from "./ConversationSummaryPanel";
import { MdOutlineNoteAlt } from "react-icons/md";
import { Translation } from "../../translation/translation";

const { Title } = Typography;
const { confirm } = Modal;

const ConversationHeader = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const translation = Translation().conversations.footer;

  const currentConversation = state.conversations.currentConversation;

  const directLine = new DirectLine({
    secret: state.currentAccount.account.bots.find(
      (bot) => bot.bot_id === currentConversation.bot_id
    )?.bot_liveagent_directline_key,
    conversationId: getCookieValue(
      `conversationId-${currentConversation?.bot_id}=`
    ),
  });

  const handleRequestFeedback = () => {
    const botDetails = state.currentAccount.account.bots.filter(
      (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
    );
    // Request Feedback Template
    const requestFeedbackTemplate = (
      state.conversations.liveAgentSettingsAcrossBots?.bots?.find(
        (bot) => bot.botId === state.conversations.currentConversation.bot_id
      ) || state.conversations.liveAgentSettingsAcrossBots
    )?.requestFeedbackTemplate;

    const currentBot = botDetails[0];

    let channelConfigurationInfo = {};

    const broadcastVersion = parseInt(
      state.currentAccount.account.bots.find(
        ({ bot_id }) => bot_id === currentBot.bot_id
      )?.bot_channel_info?.WHATSAPP?.waba_broadcast_version
    );

    const wabaProviderName =
      wabaProvider[currentBot.bot_channel_info.WHATSAPP.waba_provider]
        .providerReference.providerName;

    // For version 4, the channel configuration info should be camelCase
    if (broadcastVersion !== 4) {
      // Common to both INEXTLABS-CLOUD and 360DIALOG
      channelConfigurationInfo["api-provider"] = wabaProviderName;

      if (wabaProviderName === "INEXTLABS-CLOUD") {
        channelConfigurationInfo["phone-number-id"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
      } else {
        channelConfigurationInfo["waba-key"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_apikey;
      }
    } else {
      // Common to both INEXTLABS-CLOUD and 360DIALOG
      channelConfigurationInfo["apiProvider"] = wabaProviderName;

      if (wabaProviderName === "INEXTLABS-CLOUD") {
        channelConfigurationInfo["phoneNumberId"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
      } else {
        channelConfigurationInfo["wabaKey"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_apikey;
      }
    }

    const requestFeedbackBroadcastPayload = {
      bot: currentBot,
      broadcastContent: {
        campaignName: "Request Feedback",
        channel: state.conversations.currentConversation.channel,
        recipient: `+${state.conversations.currentConversation.conversation_id}`,
        template: {
          logToTranscripts: true,
          templateMessage: "Request Feedback (Template Message)",
          namespace: currentBot.bot_channel_info.WHATSAPP.waba_namespace,
          language: {
            policy: "deterministic",
            code: requestFeedbackTemplate?.language,
          },
          name: requestFeedbackTemplate?.name,
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: state.conversations.currentConversation.name,
                },
              ],
            },
            {
              type: "button",
              sub_type: "quick_reply",
              index: "0",
              parameters: [
                {
                  type: "payload",
                },
              ],
            },
          ],
        },
      },
      channelConfigurationInfo,
      mediaContent: {
        hasMedia: false,
        mediaType: "",
        mediaData: {},
      },
      recipientType: "single",
      submitType: "start-broadcast",
      // Check the broadcast version
      broadcastVersion: broadcastVersion,
      successMessage: "Feedback request sent successfully",
      component:
        "request-feedback-" +
        state.conversations.currentConversation._id +
        "-button",
    };

    dispatchMiddleware({
      type: "START_BROADCAST",
      payload: {
        ...requestFeedbackBroadcastPayload,
      },
    });
  };

  const ConversationNotes = () => {
    return (
      <Tooltip title={translation.notes.infoText}>
        <MdOutlineNoteAlt
          size={22}
          style={{
            color: "#6C6B6B",
            marginTop: "3px",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "conversation-notes-info-panel",
              },
            });
            state.infoPanel["conversation-info-panel"]?.visible &&
              dispatchReducer({
                type: "CLOSE_INFO_PANEL",
                payload: {
                  component: "conversation-info-panel",
                },
              });
          }}
        />
      </Tooltip>
    );
  };

  return (
    <Row
      align="middle"
      style={{
        backgroundColor: "#FAFBFA",
        height: 60,
      }}
    >
      <Col span={16}>
        <Space
          size={15}
          style={{ paddingLeft: 15, cursor: "pointer" }}
          onClick={() => {
            dispatchMiddleware({
              type: "MWD_LOAD_CONTACT_MANAGER_DATA",
              payload: { component: "contact-manager-table" },
            });

            state.infoPanel["conversation-notes-info-panel"]?.visible &&
              dispatchReducer({
                type: "CLOSE_INFO_PANEL",
                payload: {
                  component: "conversation-notes-info-panel",
                },
              });

            dispatchReducer({
              type: "OPEN_INFO_PANEL",
              payload: {
                component: "conversation-info-panel",
              },
            });
          }}
        >
          <Avatar
            size="large"
            src={
              <Image
                preview={false}
                src={
                  state.conversations.currentConversation.channel &&
                  channels[
                    state.conversations.currentConversation.channel.toUpperCase()
                  ] &&
                  channels[
                    state.conversations.currentConversation.channel.toUpperCase()
                  ].logo
                }
              />
            }
          />
          <Row>
            <Col span={24}>
              <Title style={{ margin: 0 }} level={5}>
                {state.conversations.currentConversation.name}
              </Title>
            </Col>
          </Row>
        </Space>
      </Col>
      {state.conversations.liveConversations.includes(
        state.conversations.currentConversation._id
      ) ? (
        <Col span={8}>
          <Space
            style={{ width: "100%", justifyContent: "right", paddingRight: 15 }}
          >
            {state.apiCallStatus[
              "agent-leave-" +
                state.conversations.currentConversation._id +
                "-button"
            ] &&
            state.apiCallStatus[
              "agent-leave-" +
                state.conversations.currentConversation._id +
                "-button"
            ].loading ? (
              <ComponentLoader
                component={
                  "agent-leave-" +
                  state.conversations.currentConversation._id +
                  "-button"
                }
                loadingMessage="Leaving conversation..."
                errorMessage=""
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to leave the conversation?",
                    content:
                      "When you click the OK button, you will exit the conversation",
                    onOk() {
                      dispatchReducer({
                        type: "START_API_TRANSACTION",
                        payload: {
                          component:
                            "agent-leave-" +
                            state.conversations.currentConversation._id +
                            "-button",
                        },
                      });

                      directLine
                        .postActivity({
                          type: "message",
                          text: "*!**end**!*",
                          value: {
                            eventType: "customEvent",
                            eventProperties: {
                              conversation_id:
                                state.conversations.currentConversation
                                  .conversation_id,
                              user_id:
                                state.conversations.currentConversation.user_id,
                            },
                          },
                          from: {
                            id: state.currentUser.user.userID,
                            name: state.currentUser.user.displayName,
                            role: "agent",
                          },
                        })
                        .subscribe(
                          (id) => {
                            console.log("Posted activity, assigned ID ", id);

                            if (id !== "retry") {
                              id = id?.split("|")[0];

                              // Set the conversation ID in a cookie with a 24-hour expiration
                              setCookie(
                                "conversationId-" + currentConversation?.bot_id,
                                id,
                                1
                              );

                              dispatchMiddleware({
                                type: "MWD_CONVERSATIONS_AGENT_LEAVE",
                                payload: {
                                  component:
                                    "agent-leave-" +
                                    state.conversations.currentConversation
                                      ._id +
                                    "-button",
                                  botId:
                                    state.conversations.currentConversation
                                      .bot_id,
                                  conversationId:
                                    state.conversations.currentConversation
                                      .conversation_id,
                                },
                              });

                              dispatchReducer({
                                type: "SUCCESS_API_TRANSACTION",
                                payload: {
                                  component:
                                    "agent-leave-" +
                                    state.conversations.currentConversation
                                      ._id +
                                    "-button",
                                },
                              });

                              // Remove the conversation from the on-hold list
                              state.conversations.onHoldConversations.includes(
                                state.conversations.currentConversation._id
                              ) &&
                                dispatchReducer({
                                  type: "RD_CLEAR_ON_HOLD_CONVERSATION",
                                  payload: {
                                    contactId:
                                      state.conversations.currentConversation
                                        ._id,
                                  },
                                });
                            } else {
                              openNotificationWithIcon(
                                "error",
                                "Unable to leave the conversation. Please try again later"
                              );

                              dispatchReducer({
                                type: "ERROR_API_TRANSACTION",
                                payload: {
                                  component:
                                    "agent-leave-" +
                                    state.conversations.currentConversation
                                      ._id +
                                    "-button",
                                },
                              });
                            }
                          },

                          (error) => {
                            openNotificationWithIcon("error", error.message);
                            console.log("Error posting activity", error);
                            dispatchReducer({
                              type: "ERROR_API_TRANSACTION",
                              payload: {
                                component:
                                  "agent-leave-" +
                                  state.conversations.currentConversation._id +
                                  "-button",
                              },
                            });
                          }
                        );
                    },
                    onCancel() {},
                  });
                }}
              >
                Leave Conversation
              </Button>
            )}
          </Space>
        </Col>
      ) : state.conversations.currentConversation.channel?.toUpperCase() ===
        "WHATSAPP" ? (
        <Col span={8}>
          <Space
            style={{
              width: "100%",
              justifyContent: "right",
              paddingRight: 15,
            }}
          >
            <ConversationSummaryPanel />
            <ConversationNotes />

            {state.apiCallStatus[
              "request-feedback-" +
                state.conversations.currentConversation._id +
                "-button"
            ]?.loading ? (
              <ComponentLoader
                component={
                  "request-feedback-" +
                  state.conversations.currentConversation._id +
                  "-button"
                }
                loadingMessage="Requesting feedback..."
                errorMessage=""
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to  Request Feedback?",
                    content:
                      "When you click the OK button, a request for feedback will be sent",
                    onOk() {
                      handleRequestFeedback();
                    },
                    onCancel() {},
                  });
                }}
              >
                Request Feedback
              </Button>
            )}
          </Space>
        </Col>
      ) : (
        <Col span={8}>
          <Space
            style={{
              width: "100%",
              justifyContent: "right",
              paddingRight: 15,
            }}
          >
            <ConversationSummaryPanel />
            <ConversationNotes />
          </Space>
        </Col>
      )}
    </Row>
  );
};

export default ConversationHeader;
