import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  Row,
  Col,
  Form,
  Button,
  Space,
  Tooltip,
  Modal,
  Dropdown,
  Input,
  Typography,
} from "antd";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { AiOutlineSend } from "react-icons/ai";
import { MdOutlineQuickreply, MdOutlineNoteAlt } from "react-icons/md";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { wabaProvider } from "../../constants/enums";
import CannedResponses from "../accounts-settings/canned-response/CannedResponses";
import AiAssistantPanel from "./AiAssistantPanel";
import ConversationSummaryPanel from "./ConversationSummaryPanel";
import Attachments from "./Attachments";
import { DirectLine } from "botframework-directlinejs";
import openNotificationWithIcon, {
  openErrorNotificationWithDeveloperMessage,
} from "../../utils/notification";
import { apiEndPoints } from "../../constants/enums";
import { useConnectApi } from "../../connect/connectApi";
import "./Conversations.less";
import { getCookieValue, setCookie } from "../../utils/utility";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;

const ConversationFooter = () => {
  const { callApi } = useConnectApi();
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const messageRef = useRef(null);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [showCannedResponses, setShowCannedResponses] = useState(false);
  //Attachments
  const [uploadedFile, setUploadedFile] = useState();

  const [selectedMessage, setSelectedMessage] = useState();
  const [isProfanityFound, setIsProfanityFound] = useState(false);

  const currentConversation = state.conversations.currentConversation;

  const directLine = new DirectLine({
    secret: state.currentAccount.account.bots.find(
      (bot) => bot.bot_id === currentConversation.bot_id
    )?.bot_liveagent_directline_key,
    conversationId: getCookieValue(
      `conversationId-${currentConversation?.bot_id}=`
    ),
  });

  //live Agent Settings based on bot
  let liveAgentSettings =
    state.conversations.liveAgentSettingsAcrossBots?.bots?.find(
      (bot) => bot.botId === state.conversations.currentConversation.bot_id
    ) || state.conversations.liveAgentSettingsAcrossBots;

  const filteredBot = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
  );

  const handleRecentMessageSubmit = (e) => {
    const message = e?.message ?? e;

    // Call the content moderation API to check for profanity
    if (
      ["STANDARD", "STARTER"].includes(filteredBot.subscription_package) &&
      liveAgentSettings?.liveAgentProfanity
    ) {
      callApi({
        urls: [apiEndPoints.CONTENT_MODERATION_CHECK_PROFANITY],
        options: {
          method: "POST",
          body: {
            text: message,
          },
        },
        onSuccess: ([profanityCheck]) => {
          setSelectedMessage();
          if (profanityCheck.foundProfanity) {
            setSelectedMessage(profanityCheck.cleanText);
            setIsProfanityFound(profanityCheck.foundProfanity);
            profanityCheck.foundProfanity &&
              openNotificationWithIcon("error", "Profanity word found");
          } else {
            sendAgentMessage(message);
            setIsProfanityFound(false);
          }
        },
      });
    } else {
      sendAgentMessage(message);
    }
  };

  const sendAgentMessage = (message) => {
    // Set focus on the message input
    setTimeout(() => {
      messageRef?.current?.focus();
    }, 400);
    dispatchMiddleware({
      type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
      payload: {
        //When sending message, a (sending...) loader is handled by the component
        component:
          "agent-message-" +
          state.conversations.currentConversation._id +
          "-form",
        message,
        botId: state.conversations.currentConversation.bot_id,
        conversationId: state.conversations.currentConversation.conversation_id,
        isSending: true,
      },
    });
    directLine
      .postActivity({
        type: "message",
        text: message,
        value: {
          eventType: "customEvent",
          eventProperties: {
            conversation_id:
              state.conversations.currentConversation.conversation_id,
            user_id: state.conversations.currentConversation.user_id,
          },
        },
        from: {
          id: state.currentUser.user.userID,
          name: state.currentUser.user.displayName,
          role: "agent",
        },
      })
      .subscribe(
        (id) => {
          messageRef?.current?.focus();
          console.log("Posted activity, assigned ID ", id);
          if (id !== "retry") {
            // Set the conversation ID in a cookie with a 24-hour expiration
            setCookie(
              "conversationId-" + currentConversation?.bot_id,
              id?.split("|")[0],
              1
            );
            // To fix the issue of message re-rendering, a filter based on the date has been implemented in the reducer.
            // In web chat, the sending and sent time appear to be the same. so, we delay sent response by 1 second.
            setTimeout(() => {
              dispatchMiddleware({
                type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
                payload: {
                  // Based on component, sending... loader will remove
                  component:
                    "agent-message-" +
                    state.conversations.currentConversation._id +
                    "-form",
                  message,
                  botId: state.conversations.currentConversation.bot_id,
                  conversationId:
                    state.conversations.currentConversation.conversation_id,
                  isSending: false,
                  //For bot side needs to complete
                  deliveryStatus:
                    state.conversations.currentConversation.channel?.toUpperCase() ===
                    "WHATSAPP"
                      ? "sent"
                      : undefined,
                  directlineMessageId: id,
                },
              });
            }, 1000);

            setSelectedMessage();
          } else {
            openNotificationWithIcon(
              "error",
              "Unable to send message. Please try again later"
            );
            // Handle the failed message from  transcript
            dispatchMiddleware({
              type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
              payload: {
                // Based on component, sending... loader will remove and show failed icon
                component:
                  "agent-message-" +
                  state.conversations.currentConversation._id +
                  "-form",
                message,
                botId: state.conversations.currentConversation.bot_id,
                conversationId:
                  state.conversations.currentConversation.conversation_id,
                isSending: false,
                isSendFailed: true,
              },
            });
            appInsights.trackException({
              exception: "Posted activity, assigned ID retry",
              properties: {
                title: "Conversation V2 Agent Send Message Error",
                clientId: state.currentAccount.account.client_id,
                environment: mode,
              },
            });
          }
        },
        (error) => {
          // Handle the failed message from  transcript
          dispatchMiddleware({
            type: "MWD_CONVERSATIONS_AGENT_MESSAGE",
            payload: {
              // Based on component, sending... loader will remove and show failed icon
              component:
                "agent-message-" +
                state.conversations.currentConversation._id +
                "-form",
              message,
              botId: state.conversations.currentConversation.bot_id,
              conversationId:
                state.conversations.currentConversation.conversation_id,
              isSending: false,
              isSendFailed: true,
            },
          });
          appInsights.trackException({
            exception: error,
            properties: {
              title: "Conversation V2 Agent Send Message Error",
              clientId: state.currentAccount.account.client_id,
              environment: mode,
            },
          });
          openErrorNotificationWithDeveloperMessage({
            title: "Unable to send message",
            description: error.message,
            developer_message: error?.response?.error?.message,
          });
          console.log("Error posting activity", error);
        }
      );

    resetFormField();
  };

  const resetFormField = () => {
    form.resetFields();
  };

  const handleInitiateConversation = () => {
    const botDetails = state.currentAccount.account.bots.filter(
      (bot) => bot.bot_id === state.conversations.currentConversation.bot_id
    );

    const currentBot = botDetails[0];

    let channelConfigurationInfo = {};

    const broadcastVersion = parseInt(
      state.currentAccount.account.bots.find(
        ({ bot_id }) => bot_id === currentBot.bot_id
      )?.bot_channel_info?.WHATSAPP?.waba_broadcast_version
    );

    const wabaProviderName =
      wabaProvider[currentBot.bot_channel_info.WHATSAPP.waba_provider]
        .providerReference.providerName;

    // For version 4, the channel configuration info should be camelCase
    if (broadcastVersion !== 4) {
      // Common to both INEXTLABS-CLOUD and 360DIALOG
      channelConfigurationInfo["api-provider"] = wabaProviderName;

      if (wabaProviderName === "INEXTLABS-CLOUD") {
        channelConfigurationInfo["phone-number-id"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
      } else {
        channelConfigurationInfo["waba-key"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_apikey;
      }
    } else {
      // Common to both INEXTLABS-CLOUD and 360DIALOG
      channelConfigurationInfo["apiProvider"] = wabaProviderName;

      if (wabaProviderName === "INEXTLABS-CLOUD") {
        channelConfigurationInfo["phoneNumberId"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_phone_number_id;
      } else {
        channelConfigurationInfo["wabaKey"] =
          currentBot.bot_channel_info.WHATSAPP?.waba_apikey;
      }
    }

    const initiateConversationPayload = {
      bot: currentBot,
      broadcastContent: {
        campaignName: "Initiate Conversation",
        channel: state.conversations.currentConversation.channel,
        recipient: `+${state.conversations.currentConversation.conversation_id}`,
        template: {
          logToTranscripts: true,
          templateMessage: "Initiate Conversation (Template Message by agent)",
          namespace: currentBot.bot_channel_info.WHATSAPP.waba_namespace,
          language: {
            policy: "deterministic",
            code: liveAgentSettings?.initiateConversationTemplate?.language,
          },
          name: liveAgentSettings?.initiateConversationTemplate?.name,
          components: [
            {
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: state.conversations.currentConversation.name,
                },
              ],
            },
            {
              type: "button",
              sub_type: "quick_reply",
              index: "0",
              parameters: [
                {
                  type: "payload",
                },
              ],
            },
          ],
        },
      },
      channelConfigurationInfo,
      mediaContent: {
        hasMedia: false,
        mediaType: "",
        mediaData: {},
      },
      recipientType: "single",
      submitType: "start-broadcast",
      // Check the broadcast version
      broadcastVersion: broadcastVersion,
      successMessage: "Conversation initiated successfully",
      component:
        "agent-initiate-" +
        state.conversations.currentConversation._id +
        "-button",
    };

    dispatchMiddleware({
      type: "START_BROADCAST",
      payload: {
        ...initiateConversationPayload,
      },
    });
  };

  const checkFor24Hours = () => {
    if (state.conversations.currentConversation.messages) {
      const isFromUserOnly = state.conversations.currentConversation.messages
        .filter((message) => message.is_from_user)
        ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));

      const lastUserMessageTime = new Date(
        isFromUserOnly[isFromUserOnly.length - 1]?.date
      );
      const currentTime = new Date();

      const hoursBetweenDates =
        Math.abs(lastUserMessageTime.getTime() - currentTime.getTime()) /
        (60 * 60 * 1000);

      if (hoursBetweenDates >= 24) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  };

  const handleInputChange = () => {
    setIsProfanityFound(false);
  };

  // If refreshed - handle the on-hold conversations
  window.onbeforeunload = () =>
    localStorage.setItem(
      "onHoldConversations",
      JSON.stringify(state.conversations.liveConversations)
    );

  // Set the selected message in the input field ( Canned response, AI assistant)
  useEffect(() => {
    state.conversations.liveConversations.includes(
      state.conversations.currentConversation._id
    ) &&
      !state.conversations.onHoldConversations.includes(
        state.conversations.currentConversation._id
      ) &&
      form.setFieldsValue({
        message: selectedMessage?.trim(),
      });
  }, [selectedMessage]);

  // Focus on input field
  useEffect(() => {
    if (!isEmojiPickerVisible) {
      if (messageRef?.current) {
        messageRef.current.focus();
      }
    }
  }, [
    state.conversations.liveConversations,
    selectedMessage,
    isEmojiPickerVisible,
  ]);

  return (
    <>
      {state.conversations.liveConversations.includes(
        state.conversations.currentConversation._id
      ) &&
      !state.conversations.onHoldConversations.includes(
        state.conversations.currentConversation._id
      ) ? (
        <Form
          form={form}
          onFinish={handleRecentMessageSubmit}
          autoComplete="off"
        >
          <Row
            style={{
              padding: state.infoPanel["conversation-notes-info-panel"]?.visible
                ? "15px 25px 15px 15px"
                : "15px 15px",
              backgroundColor: "#FAFBFA",
            }}
            gutter={[8, 3]}
          >
            <Col span={23}>
              <Form.Item
                name="message"
                style={{ marginBottom: 5 }}
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <TextArea
                  ref={messageRef}
                  style={{
                    borderRadius: 5,
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#FFFFFF",
                  }}
                  size={5}
                  autoSize={{ minRows: 1 }}
                  onChange={handleInputChange}
                  placeholder="Type a new message"
                  onPressEnter={(e) => {
                    e.preventDefault();
                    if (e.target.value.trim() !== "") {
                      handleRecentMessageSubmit(e.target.value);
                    }
                  }}
                  onKeyUp={(e) => {
                    // To get the cursor position
                    const { selectionStart } = e.target;
                    form.setFieldsValue({
                      cursorPosition: selectionStart,
                    });
                  }}
                  onMouseUp={(e) => {
                    // To get the cursor position
                    const { selectionStart } = e.target;
                    form.setFieldsValue({
                      cursorPosition: selectionStart,
                    });
                  }}
                />
              </Form.Item>

              {/* Profinity warning */}
              {isProfanityFound && (
                <Text type="danger"> Profanity found in the message </Text>
              )}
            </Col>

            <Col span={1}>
              <Button
                icon={
                  <AiOutlineSend
                    size={20}
                    style={{
                      marginTop: 3,
                    }}
                  />
                }
                style={{
                  borderRadius: 5,
                }}
                disabled={
                  state.apiCallStatus[
                    "agent-leave-" +
                      state.conversations.currentConversation._id +
                      "-button"
                  ]?.loading ||
                  // While send attachment disable Input box send button
                  uploadedFile
                }
                type="primary"
                htmlType="submit"
              />
            </Col>

            <Col
              span={24}
              style={{
                height: 30,
              }}
            >
              <Space>
                <Space size={11}>
                  {/* Emoji */}
                  <Tooltip title="Emoji">
                    <Dropdown
                      trigger={["click"]}
                      open={isEmojiPickerVisible}
                      onOpenChange={(visible) => {
                        setIsEmojiPickerVisible(visible);
                      }}
                      dropdownRender={() => (
                        <Space
                          style={{
                            marginBottom: 10,
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                          }}
                        >
                          <Picker
                            data={data}
                            previewPosition="none"
                            onEmojiSelect={(emoji) => {
                              const cursorPosition =
                                form.getFieldValue("cursorPosition");
                              const message = form.getFieldValue("message");
                              if (!message) {
                                form.setFieldsValue({
                                  message: emoji.native,
                                });
                              } else {
                                form.setFieldsValue({
                                  message:
                                    //Based on the cursor position, add the emoji
                                    //Front + emoji + back
                                    message?.slice(0, cursorPosition) +
                                    emoji.native +
                                    message?.slice(cursorPosition),
                                });
                              }
                              setIsEmojiPickerVisible(false);
                            }}
                          />
                        </Space>
                      )}
                    >
                      <HiOutlineEmojiHappy
                        size={23}
                        style={{
                          color: isEmojiPickerVisible
                            ? "#F05742"
                            : "rgb(108 107 107)",
                          marginTop: "3px",
                          cursor: "pointer",
                        }}
                      />
                    </Dropdown>
                  </Tooltip>

                  {/* Attachments */}
                  {["WHATSAPP", "GBM"].includes(
                    state.conversations.currentConversation.channel?.toUpperCase()
                  ) && (
                    <Attachments
                      uploadedFile={uploadedFile}
                      setUploadedFile={setUploadedFile}
                    />
                  )}

                  {/* Canned Responses */}
                  <Col span={1}>
                    <Tooltip title="Canned Responses">
                      {showCannedResponses ? (
                        <MdOutlineQuickreply
                          size={21}
                          style={{
                            color: "#F05742",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <MdOutlineQuickreply
                          size={21}
                          style={{
                            color: "rgb(108 107 107)",
                            marginTop: "3px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowCannedResponses(true);
                          }}
                        />
                      )}
                    </Tooltip>

                    <Modal
                      bodyStyle={{ height: 550, overflow: "auto" }}
                      open={showCannedResponses}
                      width="1250px"
                      onOk={() => {
                        setShowCannedResponses(false);
                      }}
                      onCancel={() => {
                        setShowCannedResponses(false);
                      }}
                      footer={null}
                    >
                      <CannedResponses
                        type="cannedResponse"
                        setShowCannedResponses={setShowCannedResponses}
                        setSelectedMessage={setSelectedMessage}
                        selectedMessage={selectedMessage}
                      />
                    </Modal>
                  </Col>

                  {/* AI Assistant */}
                  <AiAssistantPanel
                    setSelectedMessage={setSelectedMessage}
                    selectedMessage={selectedMessage}
                  />

                  {/* Conversation summary */}
                  <ConversationSummaryPanel />

                  {/* Conversation notes */}
                  <Tooltip title="Conversation Notes">
                    <MdOutlineNoteAlt
                      size={22}
                      style={{
                        color: "#6C6B6B",
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component: "conversation-notes-info-panel",
                          },
                        });
                        state.infoPanel["conversation-info-panel"]?.visible &&
                          dispatchReducer({
                            type: "CLOSE_INFO_PANEL",
                            payload: {
                              component: "conversation-info-panel",
                            },
                          });
                      }}
                    />
                  </Tooltip>
                </Space>
              </Space>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          {state.conversations.currentConversation.channel?.toUpperCase() ===
            "WHATSAPP" && checkFor24Hours() ? (
            //
            //INITIATE CONVERSATION BUTTON - AFTER 24 HOURS (FOR WHATSAPP ONLY)
            //
            <Space
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.apiCallStatus[
                "agent-initiate-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ] &&
              state.apiCallStatus[
                "agent-initiate-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ].loading ? (
                <ComponentLoader
                  component={
                    "agent-initiate-" +
                    state.conversations.currentConversation._id +
                    "-button"
                  }
                  loadingMessage="Initiating conversation..."
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    !state.conversations.currentConversation.user_id
                      ? "Cannot initiate conversation"
                      : state.conversations.coAgentLiveConversations.includes(
                          state.conversations.currentConversation._id
                        )
                      ? "Already talking to another agent"
                      : state.currentUser.permission.JOIN_CONVERSATION //USER ACTION NAME REMAINS SAME FOR JOIN & INITIATE CONVERSATION
                      ? `It has been more than 24 hours since the customer last replied. 
                      You will need to re-initiate the conversation with a template message and 
                      wait for the customer to respond.`
                      : "You do not have required permission"
                  }
                >
                  <Button
                    type="primary"
                    disabled={
                      !state.currentUser.permission.JOIN_CONVERSATION ||
                      state.conversations.coAgentLiveConversations.includes(
                        state.conversations.currentConversation._id
                      ) ||
                      !state.conversations.currentConversation.user_id
                    }
                    onClick={() => {
                      confirm({
                        title:
                          "Are you sure you want to initiate the conversation?",
                        content:
                          "When you click the OK button, you will start the conversation",
                        onOk() {
                          handleInitiateConversation();
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Initiate Conversation
                  </Button>
                </Tooltip>
              )}
            </Space>
          ) : (
            //
            //JOIN CONVERSATION BUTTON - FOR ALL CHANNEL, INCLUDING WHATSAPP (IF LESS THAN 24 HORUS)
            //
            <Space
              style={{
                width: "100%",
                height: 75,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {state.apiCallStatus[
                "agent-join-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ] &&
              state.apiCallStatus[
                "agent-join-" +
                  state.conversations.currentConversation._id +
                  "-button"
              ].loading ? (
                <ComponentLoader
                  component={
                    "agent-join-" +
                    state.conversations.currentConversation._id +
                    "-button"
                  }
                  loadingMessage="Joining conversation..."
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    !state.conversations.currentConversation.user_id
                      ? "Cannot join conversation"
                      : state.conversations.coAgentLiveConversations.includes(
                          state.conversations.currentConversation._id
                        )
                      ? "Already talking to another agent"
                      : state.currentUser.permission.JOIN_CONVERSATION
                      ? state.conversations.liveConversations.includes(
                          state.conversations.currentConversation._id
                        ) &&
                        state.conversations.onHoldConversations.includes(
                          state.conversations.currentConversation._id
                        )
                        ? "Resume Conversation"
                        : "Join Conversation"
                      : "You do not have required permission"
                  }
                >
                  <Button
                    type="primary"
                    disabled={
                      !state.currentUser.permission.JOIN_CONVERSATION ||
                      state.conversations.coAgentLiveConversations.includes(
                        state.conversations.currentConversation._id
                      ) ||
                      !state.conversations.currentConversation.user_id
                    }
                    onClick={() => {
                      confirm({
                        title:
                          "Are you sure you want to join the conversation?",
                        content:
                          "When you click the OK button, you will be joined to the conversation",
                        onOk() {
                          dispatchReducer({
                            type: "START_API_TRANSACTION",
                            payload: {
                              component:
                                "agent-join-" +
                                state.conversations.currentConversation._id +
                                "-button",
                            },
                          });

                          directLine
                            .postActivity({
                              type: "WEB_CHAT/SEND_EVENT",
                              value: {
                                eventType: "customEvent",
                                eventProperties: {
                                  action:
                                    state.conversations.liveConversations.includes(
                                      state.conversations.currentConversation
                                        ._id
                                    ) &&
                                    state.conversations.onHoldConversations.includes(
                                      state.conversations.currentConversation
                                        ._id
                                    )
                                      ? "RESUME"
                                      : "JOIN",
                                  conversation_id:
                                    state.conversations.currentConversation
                                      .conversation_id,
                                  user_id:
                                    state.conversations.currentConversation
                                      .user_id,
                                },
                              },
                              from: {
                                id: state.currentUser.user.userID,
                                name: state.currentUser.user.displayName,
                                role: "agent",
                              },
                            })
                            .subscribe(
                              (id) => {
                                console.log(
                                  "Posted activity, assigned ID ",
                                  id
                                );
                                if (id !== "retry") {
                                  id = id?.split("|")[0];

                                  // Set the conversation ID in a cookie with a 24-hour expiration
                                  setCookie(
                                    "conversationId-" +
                                      currentConversation?.bot_id,
                                    id,
                                    1
                                  );

                                  directLine.activity$
                                    .filter(
                                      (activity) => activity.type === "message"
                                    )

                                    .subscribe((message) => {
                                      dispatchMiddleware({
                                        type: "CONVERSATION_SUBSCRIBED_MESSAGE",
                                        payload: {
                                          message_type: "MESSAGE",
                                          _id: message.value
                                            ?.userConversationId,
                                          timestamp: message.timestamp,
                                          message: message.text,
                                          attachment: message?.attachments,
                                          conversationId:
                                            state.conversations
                                              .currentConversation._id,
                                        },
                                      });

                                      console.log("received message ", message);
                                    });

                                  dispatchMiddleware({
                                    type: "MWD_CONVERSATIONS_AGENT_JOIN",
                                    payload: {
                                      component:
                                        "agent-join-" +
                                        state.conversations.currentConversation
                                          ._id +
                                        "-button",
                                      botId:
                                        state.conversations.currentConversation
                                          .bot_id,
                                      conversationId:
                                        state.conversations.currentConversation
                                          .conversation_id,
                                    },
                                  });

                                  // Remove the conversation from the on-hold list
                                  state.conversations.onHoldConversations.includes(
                                    state.conversations.currentConversation._id
                                  ) &&
                                    dispatchReducer({
                                      type: "RD_CLEAR_ON_HOLD_CONVERSATION",
                                      payload: {
                                        contactId:
                                          state.conversations
                                            .currentConversation._id,
                                      },
                                    });
                                } else {
                                  openNotificationWithIcon(
                                    "error",
                                    "Unable to join the conversation. Please try again later"
                                  );

                                  dispatchReducer({
                                    type: "ERROR_API_TRANSACTION",
                                    payload: {
                                      component:
                                        "agent-join-" +
                                        state.conversations.currentConversation
                                          ._id +
                                        "-button",
                                    },
                                  });

                                  appInsights.trackException({
                                    exception:
                                      "Posted activity, assigned ID retry",
                                    properties: {
                                      title: "Conversation V2 Agent Join Error",
                                      clientId:
                                        state.currentAccount.account.client_id,
                                      environment: mode,
                                    },
                                  });
                                }
                              },
                              (error) => {
                                appInsights.trackException({
                                  exception: error,
                                  properties: {
                                    title: "Conversation V2 Agent Join Error",
                                    clientId:
                                      state.currentAccount.account.client_id,
                                    environment: mode,
                                  },
                                });
                                dispatchReducer({
                                  type: "ERROR_API_TRANSACTION",
                                  payload: {
                                    component:
                                      "agent-join-" +
                                      state.conversations.currentConversation
                                        ._id +
                                      "-button",
                                    error,
                                  },
                                });
                                openErrorNotificationWithDeveloperMessage({
                                  title: "Unable to join the conversation",
                                  description: error.message,
                                  developer_message:
                                    error?.response?.error?.message,
                                });
                              }
                            );
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    {state.conversations.liveConversations.includes(
                      state.conversations.currentConversation._id
                    ) &&
                    state.conversations.onHoldConversations.includes(
                      state.conversations.currentConversation._id
                    )
                      ? "Resume Conversation"
                      : "Join Conversation"}
                  </Button>
                </Tooltip>
              )}
            </Space>
          )}
        </>
      )}
    </>
  );
};

export default ConversationFooter;
