import { URIs } from "./env";

export const apiEndPoints = {
  //Application level end points
  APPLICATION_ROLE: URIs.API_NODE.concat("/api/role"),
  CURRENT_USER_PROFLE: URIs.API_NODE.concat("/api/me"),
  ACCOUNT: URIs.API_NODE.concat("/api/account"),
  UPDATE_BOT_DETAILS: URIs.API_NODE.concat("/api/botSettingUpdate"),

  //Account User related end points
  ACCOUNT_USERS: URIs.API_NODE.concat("/api/user"),

  //Enums related API end points
  ENUMS: URIs.API_NODE.concat("/api/enums"),
  GENERATE_PUB_SUB_TOKEN_URL: URIs.API_NODE.concat(
    "/admin-node/v1/api/generatePubSubToken"
  ),

  //Broadcast settings related API end points
  BROADCAST_SETTINGS: URIs.API_NODE.concat("/api/account/broadcast/settings"),

  //Live agent settings related API end points
  LIVE_AGENT_SETTINGS: URIs.API_NODE.concat("/api/account/live-agent/settings"),

  //Contact Manager related API end points
  CONTACTS: URIs.API_NODE.concat("/api/contact"),

  //Client Responses related API end points
  RESPONSE: URIs.API_NODE.concat("/api/response"),
  RESPONSE_CATEGORIES: URIs.API_NODE.concat("/api/response/getresponseactions"),

  //Broadcast Manager related API end points
  BROADCAST: URIs.API_NODE.concat("/api/broadcast"),
  BROADCAST_MEDIA: URIs.BROADCAST_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/UploadMedia"
  ),

  POST_SINGLE_RECIPIENT_BROADCAST: URIs.BROADCAST_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/SingleRecipient"
  ),

  POST_MULTIPLE_RECIPIENT_BROADCAST: URIs.BROADCAST_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/orchestrators/MultiBroadcastOrchestrator"
  ),

  //Broadcast v3
  BROADCAST_MEDIA_V3: URIs.BROADCAST_FUNCTION_APP_V3.replace(
    "{apiEndPoint}",
    "/api/UploadMedia"
  ),
  POST_SINGLE_RECIPIENT_BROADCAST_V3: URIs.BROADCAST_FUNCTION_APP_V3.replace(
    "{apiEndPoint}",
    "/api/SingleRecipient"
  ),
  POST_MULTIPLE_RECIPIENT_BROADCAST_V3: URIs.BROADCAST_FUNCTION_APP_V3.replace(
    "{apiEndPoint}",
    "/api/orchestrators/MultiBroadcastOrchestrator"
  ),

  //Broadcast v4
  BROADCAST_V4: URIs.API_NODE.concat("/api/broadcast/jobs"),
  BROADCAST_MEDIA_V4: URIs.BROADCAST_FUNCTION_APP_V4.replace(
    "{apiEndPoint}",
    "/api/v4/broadcast/upload-media"
  ),
  POST_SINGLE_RECIPIENT_BROADCAST_V4: URIs.BROADCAST_FUNCTION_APP_V4.replace(
    "{apiEndPoint}",
    "/api/v4/broadcast/single-recipient"
  ),
  POST_MULTIPLE_RECIPIENT_BROADCAST_V4: URIs.BROADCAST_FUNCTION_APP_V4.replace(
    "{apiEndPoint}",
    "/api/v4/broadcast/multi-recipient"
  ),

  WHATSAPP_USAGE: URIs.API_NODE.concat("/api/broadcast/whatsapp/usage"),
  AUTOMATED_REPLIES: URIs.API_NODE.concat("/api/broadcast/auto-reply"),

  //Template related API end points
  TEMPLATE: URIs.API_NODE.concat("/api/broadcast/templates"),

  //support Ticket related API end points
  SUPPORT_TICKETS: URIs.API_NODE.concat("/api/supportTicket"), //Get by ID, Get by mail ID, Reply ticket and Create new ticket

  //Api subscription related Api end points
  GET_API_KEY: URIs.API_KEY_FUNCTION_APP.concat(
    "/api/GetAPIMSubscrptionKeys?code=LdwAVPsgXfMewSk0E_23SbkQ_e2RzCvX7Hfp2VW8Jh-XAzFus7Mruw=="
  ),
  GENERATE_API_KEY: URIs.API_KEY_FUNCTION_APP.concat(
    "/api/GenerateAPIMSubscription?code=5L8fDZZoHwNSQhBkPXikFxrnywElsVO0S0y4CSuzOl__AzFu5MNVkw=="
  ),
  REGENERATE_API_KEY: URIs.API_KEY_FUNCTION_APP.concat(
    "/api/ReGenerateAPIMPrimarySubscriptionKey?code=rB0fsNukPDnDMaCXXK_XG1imeZcCBoTgfdmzJUCBnNx_AzFuJ99OmQ=="
  ),
  APP_REGISTRATION: URIs.API_NODE.concat("/api/azure/ADB2C/appRegistration"),

  //QNA related API end points
  QUESTIONS_AND_ANSWER: URIs.API_NODE.concat("/api/knowledgeBase"),

  //Conversation related API end points
  CONVERSATIONS_CONTACTS: URIs.API_NODE.concat(
    "/api/transcript/conversationList"
  ),
  CONVERSATIONS_TRANSCRIPTS: URIs.API_NODE.concat(
    "/api/transcript/conversationMessages"
  ),
  // Need to use this end point for getting the conversation details
  CONVERSATIONS: URIs.API_NODE.concat("/api/transcript"),

  CONVERSATION_STATUS: URIs.API_NODE.concat(
    "/api/conversation/conversation-status"
  ),
  LIVE_AGENT_NOTES: URIs.API_NODE.concat("/api/transcript/liveAgentNotes"),
  AI_ASSISTANT: URIs.AI_ASSISTANT.concat(
    "/completions?api-version=2023-09-15-preview"
  ),
  AI_CONVERSATION_SUMMARY: URIs.AI_CONVERSATION_SUMMARY.concat(
    "/completions?api-version=2023-09-15-preview"
  ),

  //Unrecognized Intents related API end points
  UNRECOGNIZED_INTENTS: URIs.API_NODE.concat(
    "/api/knowledgeBase/unrecognizedIntents"
  ),
  INTENTS_AND_PHRASES: URIs.API_NODE.concat(
    "/api/knowledgeBase/intentAndPhrases"
  ),
  TEST_INTENTS: URIs.API_NODE.concat("/api/knowledgeBase/Intent"),

  //Knowledge Base related API end points
  BOT_KNOWLEDGEBASE_UPLOAD: URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/knowledgebase/source"
  ),
  BOT_KNOWLEDGEBASE: URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/knowledgebase"
  ),
  BOT_KNOWLEDGEBASE_SECTION: URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/knowledgebase/section"
  ),
  BOT_KNOWLEDGEBASE_TEST: URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/knowledgebase/query_search"
  ),
  BOT_KNOWLEDGEBASE_STATUS: URIs.BOT_KNOWLEDGEBASE_FUNCTION_APP.replace(
    "{apiEndPoint}",
    "/api/knowledgebase/status"
  ),
  // WhatsApp Flow related API end points
  WHATSAPPFLOWS: URIs.API_NODE.concat("/api/knowledgebase/whatsappflows"),

  //Analytics API end points
  RESPONSES_BY_CHANNEL: URIs.API_NODE.concat(
    "/api/response/leadscountbychannel"
  ),
  RESPONSES_BY_ACTION: URIs.API_NODE.concat("/api/response/leadscountbyaction"),
  RESPONSES_BY_ASSIGNED_PERSON: URIs.API_NODE.concat(
    "/api/response/leadscountbyassignedperson"
  ),
  RESPONSES_BY_DATE: URIs.API_NODE.concat("/api/response/countResponseByDate"),
  BROADCAST_MESSAGE_BY_DATE: URIs.API_NODE.concat(
    "/api/broadcast/messagecountbydate"
  ),
  BROADCAST_MESSAGE_BY_TEMPLATE: URIs.API_NODE.concat(
    "/api/broadcast/messagecountbytemplate"
  ),
  BROADCAST_MESSAGE_BY_CHANNEL: URIs.API_NODE.concat(
    "/api/broadcast/messagecountbychannel"
  ),
  BROADCAST_BY_CHANNEL: URIs.API_NODE.concat(
    "/api/broadcast/broadcastCountbyChannel"
  ),
  BROADCAST_BY_DATE: URIs.API_NODE.concat(
    "/api/broadcast/broadcastCountbyDate"
  ),
  BROADCAST_BY_TEMPLATE: URIs.API_NODE.concat(
    "/api/broadcast/broadcastCountbyTemplate"
  ),
  MESSAGE_COUNT_BY_CHANNEL: URIs.API_NODE.concat(
    "/api/transcript/messagesByChannel"
  ),
  INTENT_COUNT: URIs.API_NODE.concat("/api/transcript/intentcount"),
  KNOWLEDGE_BASE_MESSAGE: URIs.API_NODE.concat("/api/transcript/getkbmessages"),
  CONVERSATION_BY_CHANNEL: URIs.API_NODE.concat(
    "/api/transcript/conversationByChannel"
  ),
  CONVERSATION_BY_BROWSER: URIs.API_NODE.concat(
    "/api/transcript/conversationByBrowserName"
  ),
  CONVERSATION_BY_LOCATION: URIs.API_NODE.concat(
    "/api/transcript/conversationByLocation"
  ),
  CONVERSATION_BY_DATE: URIs.API_NODE.concat(
    "/api/transcript/conversationByDate"
  ),
  MESSAGE_BY_CHANNEL: URIs.API_NODE.concat("/api/transcript/messagesByChannel"),
  MESSAGE_BY_DATE: URIs.API_NODE.concat("/api/transcript/messagesByDate"),
  NONE_INTENT_MESSAGE_COUNT: URIs.API_NODE.concat(
    "/api/transcript/noneIntentCount"
  ),
  ANALYTICS_CONTACTS: URIs.API_NODE.concat("/api/contact/analytics"),
  // Unrecognized Intent Analytics API end points
  ANALYTICS_UNRECOGNIZED_INTENTS: URIs.API_NODE.concat("/api/knowledgeBase"),

  //DashBoard API end points
  BROADCAST_COUNT: URIs.API_NODE.concat("/api/broadcast/broadcastCountbyDate"),
  BROADCAST_MESSAGE_COUNT: URIs.API_NODE.concat(
    "/api/broadcast/messagecountbydate"
  ),
  CLIENT_RESPONSES_COUNT: URIs.API_NODE.concat(
    "/api/response/countResponseByDate"
  ),
  MESSAGES_COUNT: URIs.API_NODE.concat("/api/transcript/messagesByDate"),
  CONVERSATIONS_COUNT: URIs.API_NODE.concat(
    "/api/transcript/conversationByDate"
  ),
  LATEST_NEWS_UPDATE: URIs.API_NODE.concat("/api/latest-update"),
  WAITING_FOR_LIVE_AGENT_COUNT: URIs.API_NODE.concat(
    "/api/conversation/live-agent/waiting-count"
  ),

  //Onboarding 360Dialog API end points
  ONBOARD_WHATSAPP_360DIALOG: URIs.API_NODE.concat(
    "/api/account/completewhatsapponboarding"
  ),

  //Onboarding whatsapp using Meta
  WHATSAPP_META: URIs.API_NODE.concat("/api/account/meta"),

  //GET CUSTOMER SUBSCRIPTION DETAILS
  SUBSCRIPTION: URIs.API_NODE.concat("/api/account/subscription"),
  WHATSAPP: URIs.API_NODE.concat("/api/account/whatsapp"),

  //Conversation flow API
  CONVERSATION_FLOW: URIs.API_NODE.concat("/api/conversationflows"),

  //enums API
  GET_RESPONSES_PROPERTIES: URIs.API_NODE.concat(
    "/api/response/getresponseproperties"
  ),

  //webhooks API
  WEBHOOKS: URIs.API_NODE.concat("/api/webhookConfiguration"),
  RESPONSE_VALUE_BY_PROPERTY_NAME: URIs.API_NODE.concat(
    "/api/response/getresponsevaluebypropertyname"
  ),

  // Web service API
  LALAMOVE: URIs.API_NODE.concat("/api/lalamove"),

  //Canned responses API
  CANNED_RESPONSES: URIs.API_NODE.concat("/api/cannedResponses"),
  CANNED_RESPONSES_BY_FOLDER_NAME: URIs.API_NODE.concat(
    "/api/cannedResponses/byFolderName"
  ),

  CONTENT_MODERATION_CHECK_PROFANITY: URIs.API_NODE.concat(
    "/api/contentModeration/checkProfanity"
  ),
  //Change password
  CHANGE_PASSWORD: URIs.B2C_CHANGE_PASSWORD.concat(
    "?p=B2C_1_reset_pwd&client_id=10d33577-bc99-484d-9db1-8c489138eebc&nonce=defaultNonce&redirect_uri=https%3A%2f%2f{{mode}}&scope=openid&response_type=id_token&prompt=login"
  ),

  //Real time notification
  NOTIFICATION: URIs.API_NODE.concat("/api/notification"),
};

//Subscription package
export const subscriptionPackage = {
  STANDARD: "STANDARD",
  STARTER: "STARTER",
  NOTIFY: "NOTIFY",
};

//Contact related enums
export const contactSources = {
  manualAddContact: "Manual Add",
  contactFromConversation: "Conversation",
  importContact: "Import",
  contactFromResponse: "Response",
};

// Contact related constants
export const mandatoryContactProperties = [
  "firstName",
  "lastName",
  "displayName",
  "email",
  "mobile",
  "whatsappId",
  "googleBusinessMessagesId",
  "appleID",
  "telegramId",
  "facebookId",
  "instagramId",
  "msTeamsId",
  "skypeId",
  "viberId",
  "webChatID",
  "isUnsubscribed",
  "tags",
  "companyName",
  "address1",
  "address2",
  "city",
  "state",
  "country",
  "postCode",
];

export const systemContactProperties = [
  "_id",
  "id",
  "timestamp",
  "createdDate",
  "createdSource",
  "subscribedDate",
  "modifiedDate",
  "unsubscribedDate",
  "ConversationReferenceId",
  "isWhatsAppSameAsMobile",
  "lastConversationChannel",
  "lastConversationLocation",
];

export const defaultActionValues = {
  contact: {
    displayName: "{{UserProfileName}}",
    mobile: "{{UserPhoneNumber}}",
    email: "{{UserEmail}}",
    isUnsubscribed: "false",
    createdDate: "Date.now()",
    createdSource: "RESPONSE",
    subscribedDate: "Date.now()",
  },
  responseWithOrderCategory: {
    botId: "{{botId}}",
    category: "{{category}}",
    channel: "{{channel}}",
    clientId: "{{clientId}}",
    conversationId: "{{conversationId}}",
    deliveryAddress: "{{deliveryAddress}}",
    deliveryDate: "{{deliveryDate}}",
    firstName: "{{firstName}}",
    items: [
      {
        name: "{{items.[INDEX].name}}",
        quantity: "{{items.[INDEX].quantity}}",
        price: {
          singlePrice: "{{items.[INDEX].price.singlePrice}}",
          totalPrice: "{{items.[INDEX].price.totalPrice}}",
          currency: "{{items.[INDEX].price.currency}}",
        },
        productID: "{{items.[INDEX].productID}}",
      },
    ],
    leadDate: "{{leadDate}}",
    orderId: "{{orderId}}",
    partitionKey: "{{partitionKey}}",
    price: {
      totalPrice: "{{price.totalPrice}}",
      currency: "{{price.currency}}",
    },
    responseDate: "{{responseDate}}",
    shippingAddress: {
      address_1: "{{shippingAddress.address_1}}",
      address_2: "{{shippingAddress.address_2}}",
      city: "{{shippingAddress.city}}",
      state: "{{shippingAddress.state}}",
      postcode: "{{shippingAddress.postcode}}",
      country: "{{shippingAddress.country}}",
    },
    status: "{{status}}",
    tags: [],
    timestamp: {
      $date: "{{timestamp.$date}}",
    },
    userPhoneNumber: "{{userPhoneNumber}}",
    userProfileName: "{{userProfileName}}",
    version: "{{version}}",
  },
};

export const defaultResponsesProperties = [
  {
    key: "User Profile Name",
    value: "{{UserProfileName}}",
  },
  {
    key: "User Phone Number",
    value: "{{UserPhoneNumber}}",
  },
  {
    key: "User Email",
    value: "{{UserEmail}}",
  },
  {
    key: "Response Date",
    value: "{{LeadDate}}",
  },
  {
    key: "Channel",
    value: "{{Channel}}",
  },
];

export const importContactsMaxSize = 5000000;

export const ticketPriorities = {
  urgent: "Urgent",
  high: "High",
  medium: "Medium",
  low: "Low",
};

export const ticketStatus = {
  open: "Open",
  pending: "Pending",
  resolved: "Resolved",
  closed: "Closed",
};

export const ticketSources = {
  1: "Email",
  2: "Portal",
  3: "Phone",
  7: "Chat",
  9: "Feedback widget",
  10: "Outbond Email",
};

export const ticketCategory = [
  {
    label: "New template submission",
    value: "New template submission",
  },
  {
    label: "Add new channel",
    value: "Add new channel",
  },
  {
    label: "Product Issue",
    value: "Product Issue",
  },
  {
    label: "Feature Request",
    value: "Feature Request",
  },
  {
    label: "Conversation tuning",
    value: "Conversation tuning",
  },
  {
    label: "Billing",
    value: "Billing",
  },
  {
    label: "Other issues",
    value: "Other issues",
  },
];

export const botProfilePictures = {
  bot1: "/images/bot-profile-pictures/bot1.png",
  bot2: "/images/bot-profile-pictures/bot2.png",
  bot3: "/images/bot-profile-pictures/bot3.png",
  bot4: "/images/bot-profile-pictures/bot4.png",
  bot5: "/images/bot-profile-pictures/bot5.png",
  bot6: "/images/bot-profile-pictures/bot6.png",
  bot7: "/images/bot-profile-pictures/bot7.png",
  bot8: "/images/bot-profile-pictures/bot8.png",
  bot9: "/images/bot-profile-pictures/bot9.png",
  bot10: "/images/bot-profile-pictures/bot10.png",
};

export const status = {
  successIcon: "/images/status-icons/success.png",
  errorIcon: "/images/status-icons/error.png",
  exclamationIcon: "/images/status-icons/exclamation.png",
};

export const userIcons = {
  addUser: "/images/user-icons/add-user.png",
  updateUser: "/images/user-icons/update-user.png",
};

export const wabaProvider = {
  DIALOG360: {
    displayName: "Dialog 360",
    providerReference: {
      //This is to use while communicating to the provider's api.
      providerName: "dialog360",
      channelName: "whatsapp",
    },
    partnerId: "Imf3ffPA",
  },
  "DIALOG360-CLOUD": {
    displayName: "Dialog 360 Cloud",
    providerReference: {
      //This is to use while communicating to the provider's api.
      providerName: "DIALOG360-CLOUD",
      channelName: "whatsapp",
    },
    partnerId: "Imf3ffPA",
  },
  "CLOUD-API": {
    displayName: "Cloud API",
    providerReference: {
      //This is to use while communicating to the provider's api.
      providerName: "WABA-CLOUD",
      channelName: "whatsapp",
    },
    partnerId: "Imf3ffPA",
  },
  "INEXTLABS-CLOUD": {
    displayName: "Inextlabs Cloud",
    providerReference: {
      //This is to use while communicating to the provider's api.
      providerName: "INEXTLABS-CLOUD",
      channelName: "whatsapp",
    },
    partnerId: "Imf3ffPA",
  },
};

// Media size in bytes
export const maxAllowedMediaSize = {
  image: 5033165,
  video: 16567501,
  document: 104857600,
};

export const maxAllowedKnowledgebaseMediaSize = {
  Doc: 2000000,
};

export const channels = {
  WHATSAPP: {
    displayName: "WhatsApp",
    logo: "/images/channel-icons/whatsapp.png",
  },
  TELEGRAM: {
    displayName: "Telegram",
    logo: "/images/channel-icons/telegram.png",
  },
  FACEBOOK: {
    displayName: "Facebook",
    logo: "/images/channel-icons/facebook.png",
  },
  INSTAGRAM: {
    displayName: "Instagram",
    logo: "/images/channel-icons/instagram.png",
  },
  WEBCHAT: {
    displayName: "Web Chat",
    logo: "/images/channel-icons/web.png",
  },
  WEB: {
    displayName: "Web Chat",
    logo: "/images/channel-icons/web.png",
  },
  MSTEAMS: {
    displayName: "Microsoft Teams",
    logo: "/images/channel-icons/teams.png",
  },
  GBM: {
    displayName: "Google Business Messages",
    logo: "/images/channel-icons/gbm.png",
  },
  LINE: {
    displayName: "Line Messenger",
    logo: "/images/channel-icons/line.png",
  },
  ALEXA: {
    displayName: "Amazon Alexa",
    logo: "/images/channel-icons/alexa.png",
  },
  SLACK: {
    displayName: "Slack Messenger",
    logo: "/images/channel-icons/slack.png",
  },
  VIBER: {
    displayName: "Viber",
    logo: "/images/channel-icons/viber.png",
  },
  EMULATOR: {
    displayName: "Emulator",
    logo: "/images/channel-icons/emulator.png",
  },
  GBP: {
    displayName: "Google Business Profile",
    logo: "/images/channel-icons/gbm.png",
  },
  AMB: {
    displayName: "Apple Messages for Business",
    logo: "/images/channel-icons/amb.png",
  },
};

// This is to be used for form field names
export const channelList = [
  {
    _id: "INSTAGRAM",
    formFieldName: "instagramId",
  },
  {
    _id: "WEB",
    formFieldName: "webChatID",
  },
  {
    _id: "WHATSAPP",
    formFieldName: "whatsappId",
  },
  {
    _id: "GBM",
    formFieldName: "googleBusinessMessagesId",
  },
  {
    _id: "AMB",
    formFieldName: "appleID",
  },
  {
    _id: "TELEGRAM",
    formFieldName: "telegramId",
  },
  {
    _id: "FACEBOOK",
    formFieldName: "facebookId",
  },
  {
    _id: "VIBER",
    formFieldName: "viberId",
  },
  {
    _id: "MSTEAMS",
    formFieldName: "msTeamsId",
  },
];

export const responseStatus = [
  { key: "", value: "noStatus" },
  { key: "Pending", value: "Pending" },
  { key: "Completed", value: "Completed" },
  { key: "Verified", value: "Verified" },
];

//Set the 'isGlobal' property to false in the 'Status' and 'Assigned To' dropdowns within the Responses header in order to show or hide the dropdowns.
export const responseConvertedCustomFields = ["status", "assigned"];
export const responseDropdownColumns = ["status", "assigned"];
export const responseFieldsToInclude = ["chart"];
export const systemResponseProperties = [
  "_id",
  "category",
  "version",
  "subCategory",
  "conversationId",
  "clientId",
  "botId",
  "channel",
  "responseDate",
  "timestamp",
  "partitionKey",
  "leadDate",
  "orderId",
  "items",
  "messageSid",
];

export const timeZone = [
  { value: "(GMT+00:00) Default", label: "(GMT+00:00) Default" },
  {
    value: "(GMT-12:00) International Date Line West",
    label: "(GMT-12:00) International Date Line West",
  },
  {
    value: "(GMT-11:00) Coordinated Universal Time-11",
    label: "(GMT-11:00) Coordinated Universal Time-11",
  },
  { value: "(GMT-11:00) Samoa", label: "(GMT-11:00) Samoa" },
  { value: "(GMT-10:00) Hawaii", label: "(GMT-10:00) Hawaii" },
  {
    value: "(GMT-09:30) Marquesas Islands",
    label: "(GMT-09:30) Marquesas Islands",
  },
  { value: "(GMT-09:00) Alaska", label: "(GMT-09:00) Alaska" },
  {
    value: "(GMT-08:00) Baja California",
    label: "(GMT-08:00) Baja California",
  },
  {
    value: "(GMT-08:00) Pacific Time (US & Canada)",
    label: "(GMT-08:00) Pacific Time (US & Canada)",
  },
  {
    value: "(GMT-07:00) Chihuahua,Mazatlan",
    label: "(GMT-07:00) Chihuahua,Mazatlan",
  },
  { value: "(GMT-07:00) Arizona", label: "(GMT-07:00) Arizona" },
  {
    value: "(GMT-07:00) Mountain Time (US & Canada)",
    label: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    value: "(GMT-06:00) Central Time (US & Canada)",
    label: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    value: "(GMT-06:00) Central America",
    label: "(GMT-06:00) Central America",
  },
  {
    value: "(GMT-06:00) Guadalajara,Mexico City, Monterrey",
    label: "(GMT-06:00) Guadalajara,Mexico City, Monterrey",
  },
  { value: "(GMT-06:00) Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
  {
    value: "(GMT-05:00) Bogota, Lima, Quito",
    label: "(GMT-05:00) Bogota, Lima, Quito",
  },
  {
    value: "(GMT-05:00) Eastern Time (US & Canada)",
    label: "(GMT-05:00) Eastern Time (US & Canada)",
  },
  { value: "(GMT-05:00) Indiana (East)", label: "(GMT-05:00) Indiana (East)" },
  { value: "(GMT-04:30) Caracas", label: "(GMT-04:30) Caracas" },
  {
    value: "(GMT-04:00) Atlantic Time (Canada)",
    label: "(GMT-04:00) Atlantic Time (Canada)",
  },
  { value: "(GMT-04:00) Asuncion", label: "(GMT-04:00) Asuncion" },
  { value: "(GMT-04:00) Cuiaba", label: "(GMT-04:00) Cuiaba" },
  { value: "(GMT-04:00) Santiago", label: "(GMT-04:00) Santiago" },
  {
    value: "(GMT-04:00) Georgetown, La Paz, Manaus, San Juan",
    label: "(GMT-04:00) Georgetown, La Paz, Manaus, San Juan",
  },
  { value: "(GMT-03:30) Newfoundland", label: "(GMT-03:30) Newfoundland" },
  { value: "(GMT-03:00) Buenos Aires", label: "(GMT-03:00) Buenos Aires" },
  { value: "(GMT-03:00) Brasilia", label: "(GMT-03:00) Brasilia" },
  {
    value: "(GMT-03:00) Cayenne, Fortaleza",
    label: "(GMT-03:00) Cayenne, Fortaleza",
  },
  { value: "(GMT-03:00) Montevideo", label: "(GMT-03:00) Montevideo" },
  { value: "(GMT-03:00) Greenland", label: "(GMT-03:00) Greenland" },
  {
    value: "(GMT-02:00) Coordinated Universal Time-02",
    label: "(GMT-02:00) Coordinated Universal Time-02",
  },
  { value: "(GMT-02:00) Mid-Atlantic", label: "(GMT-02:00) Mid-Atlantic" },
  { value: "(GMT-01:00) Azores", label: "(GMT-01:00) Azores" },
  { value: "(GMT-01:00) Cape Verde Is.", label: "(GMT-01:00) Cape Verde Is." },
  { value: "(GMT+00:00) Casablanca", label: "(GMT+00:00) Casablanca" },
  {
    value: "(GMT+00:00) Monrovia,Reykjavik",
    label: "(GMT+00:00) Monrovia,Reykjavik",
  },
  {
    value: "(GMT+00:00) Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London",
    label: "(GMT+00:00) Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London",
  },
  {
    value: "(GMT+01:00) Sarajevo,Skopje, Warsaw, Zagreb",
    label: "(GMT+01:00) Sarajevo,Skopje, Warsaw, Zagreb",
  },
  {
    value: "(GMT+01:00) West Central Africa",
    label: "(GMT+01:00) West Central Africa",
  },
  {
    value: "(GMT+01:00) Belgrade,Bratislava, Budapest, Ljubljana, Prague",
    label: "(GMT+01:00) Belgrade,Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    value: "(GMT+01:00) Brussels, Copenhagen,Madrid, Paris",
    label: "(GMT+01:00) Brussels, Copenhagen,Madrid, Paris",
  },
  {
    value: "(GMT+01:00) Amsterdam,Berlin, Bern, Rome,Stockholm, Vienna",
    label: "(GMT+01:00) Amsterdam,Berlin, Bern, Rome,Stockholm, Vienna",
  },
  {
    value: "(GMT+02:00) Harare, Pretoria",
    label: "(GMT+02:00) Harare, Pretoria",
  },
  { value: "(GMT+02:00) Damascus", label: "(GMT+02:00) Damascus" },
  {
    value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { value: "(GMT+02:00) Windhoek", label: "(GMT+02:00) Windhoek" },
  { value: "(GMT+02:00) Minsk", label: "(GMT+02:00) Minsk" },
  {
    value: "(GMT+02:00) Athens,Bucharest, Istanbul",
    label: "(GMT+02:00) Athens,Bucharest, Istanbul",
  },
  { value: "(GMT+02:00) Amman", label: "(GMT+02:00) Amman" },
  { value: "(GMT+02:00) Beirut", label: "(GMT+02:00) Beirut" },
  { value: "(GMT+02:00) Jerusalem", label: "(GMT+02:00) Jerusalem" },
  { value: "(GMT+02:00) Cairo", label: "(GMT+02:00) Cairo" },
  { value: "(GMT+03:00) Kuwait, Riyadh", label: "(GMT+03:00) Kuwait, Riyadh" },
  {
    value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { value: "(GMT+03:00) Baghdad", label: "(GMT+03:00) Baghdad" },
  { value: "(GMT+03:00) Nairobi", label: "(GMT+03:00) Nairobi" },
  { value: "(GMT+03:30) Tehran", label: "(GMT+03:30) Tehran" },
  { value: "(GMT+04:00) Port Louis", label: "(GMT+04:00) Port Louis" },
  { value: "(GMT+04:00) Tbilisi", label: "(GMT+04:00) Tbilisi" },
  { value: "(GMT+04:00) Baku", label: "(GMT+04:00) Baku" },
  { value: "(GMT+04:00) Yerevan", label: "(GMT+04:00) Yerevan" },
  {
    value: "(GMT+04:00) Abu Dhabi, Muscat",
    label: "(GMT+04:00) Abu Dhabi, Muscat",
  },
  { value: "(GMT+04:30) Kabul", label: "(GMT+04:30) Kabul" },
  { value: "(GMT+05:00) Yekaterinburg", label: "(GMT+05:00) Yekaterinburg" },
  {
    value: "(GMT+05:00) Islamabad,Karachi",
    label: "(GMT+05:00) Islamabad,Karachi",
  },
  { value: "(GMT+05:00) Tashkent", label: "(GMT+05:00) Tashkent" },
  {
    value: "(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi",
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi",
  },
  {
    value: "(GMT+05:30) Sri Jayawardenepura",
    label: "(GMT+05:30) Sri Jayawardenepura",
  },
  { value: "(GMT+05:45) Kathmandu", label: "(GMT+05:45) Kathmandu" },
  { value: "(GMT+06:00) Dhaka", label: "(GMT+06:00) Dhaka" },
  { value: "(GMT+06:00) Novosibirsk", label: "(GMT+06:00) Novosibirsk" },
  { value: "(GMT+06:00) Astana", label: "(GMT+06:00) Astana" },
  {
    value: "(GMT+06:30) Yangon (Rangoon)",
    label: "(GMT+06:30) Yangon (Rangoon)",
  },
  { value: "(GMT+07:00) Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
  {
    value: "(GMT+07:00) Bangkok, Hanoi,Jakarta",
    label: "(GMT+07:00) Bangkok, Hanoi,Jakarta",
  },
  { value: "(GMT+08:00) Ulaanbaatar", label: "(GMT+08:00) Ulaanbaatar" },
  { value: "(GMT+08:00) Perth", label: "(GMT+08:00) Perth" },
  { value: "(GMT+08:00) Taipei", label: "(GMT+08:00) Taipei" },
  {
    value: "(GMT+08:00) Kuala Lumpur,Singapore",
    label: "(GMT+08:00) Kuala Lumpur,Singapore",
  },
  {
    value: "(GMT+08:00) Beijing,Chongqing,Hong Kong, Urumqi",
    label: "(GMT+08:00) Beijing,Chongqing,Hong Kong, Urumqi",
  },
  { value: "(GMT+08:00) Irkutsk", label: "(GMT+08:00) Irkutsk" },
  { value: "(GMT+08:30) Pyongyang", label: "(GMT+08:30) Pyongyang" },
  { value: "(GMT+08:30) Eucla", label: "(GMT+08:30) Eucla" },
  { value: "(GMT+09:00) Seoul", label: "(GMT+09:00) Seoul" },
  {
    value: "(GMT+09:00) Osaka,Sapporo, Tokyo",
    label: "(GMT+09:00) Osaka,Sapporo, Tokyo",
  },
  { value: "(GMT+09:00) Yakutsk", label: "(GMT+09:00) Yakutsk" },
  { value: "(GMT+09:30) Darwin", label: "(GMT+09:30) Darwin" },
  { value: "(GMT+09:30) Adelaide", label: "(GMT+09:30) Adelaide" },
  { value: "(GMT+10:00) Hobart", label: "(GMT+10:00) Hobart" },
  { value: "(GMT+10:00) Vladivostok", label: "(GMT+10:00) Vladivostok" },
  {
    value: "(GMT+10:00) Guam, Port Moresby",
    label: "(GMT+10:00) Guam, Port Moresby",
  },
  { value: "(GMT+10:00) Brisbane", label: "(GMT+10:00) Brisbane" },
  {
    value: "(GMT+10:00) Canberra,Melbourne, Sydney",
    label: "(GMT+10:00) Canberra,Melbourne, Sydney",
  },
  {
    value: "(GMT+10:30) Lord Howe Island",
    label: "(GMT+10:30) Lord Howe Island",
  },
  {
    value: "(GMT+11:00) Magadan, Solomon Is.,New Caledonia",
    label: "(GMT+11:00) Magadan, Solomon Is.,New Caledonia",
  },
  { value: "(GMT+12:00) Fiji", label: "(GMT+12:00) Fiji" },
  {
    value: "(GMT+12:00) Petropavlovsk-Kamchatsky",
    label: "(GMT+12:00) Petropavlovsk-Kamchatsky",
  },
  {
    value: "(GMT+12:00) Auckland, Wellington",
    label: "(GMT+12:00) Auckland, Wellington",
  },
  {
    value: "(GMT+12:00) Coordinated Universal Time+12",
    label: "(GMT+12:00) Coordinated Universal Time+12",
  },
  {
    value: "(GMT+12:45) Chatham Islands",
    label: "(GMT+12:45) Chatham Islands",
  },
  {
    value: "(GMT+13:00) Phoenix Islands, Tokelau, Tonga",
    label: "(GMT+13:00) Phoenix Islands, Tokelau, Tonga",
  },
  { value: "(GMT+14:00) Line Islands", label: "(GMT+14:00) Line Islands" },
];

export const dateRange = [
  { label: "Today", value: "today" },
  { label: "Last 7 days", value: "sevendays" },
  { label: "Last 30 days", value: "thirtydays" },
  { label: "Last 60 days", value: "sixtydays" },
];

export const dateFormat = [
  {
    value: "MM/DD/yyyy HH:mm:ss",
    label: "MM/DD/yyyy HH:mm:ss (Ex:06/29/2021 17:02:11)",
  },
  {
    value: "MMMM DD yyyy HH:mm:ss",
    label: "MMMM DD yyyy HH:mm:ss (Ex:June 29 2021 17:02:11)",
  },
  {
    value: "DD/MM/yyyy HH:mm:ss",
    label: "DD/MM/yyyy HH:mm:ss (Ex:29/06/2021 17:02:11)",
  },
  {
    value: "DD MMMM yyyy HH:mm:ss",
    label: "DD MMMM yyyy HH:mm:ss(Ex:29 June 2021 17:02:11)",
  },
  {
    value: "YYYY/MM/DD HH:mm:ss",
    label: "YYYY/MM/DD HH:mm:ss (Ex:2021/05/21 02:43:43)",
  },
  {
    value: "YYYY/MMMM/DD HH:mm:ss",
    label: "YYYY/MMMM/DD HH:mm:ss (Ex:2021/June/21 02:43:43)",
  },
];

export const languageSelector = [
  {
    value: "EN",
    label: "English",
    icon: "/images/country-flags/united-kingdom.png",
  },
  {
    value: "CH",
    label: "Chinese(中国人)",
    icon: "/images/country-flags/china.png",
  },
];

export const countryDailingCodes = [
  { label: "(+93) Afghanistan", value: "+93" },
  { label: "(+355) Albania", value: "+355" },
  { label: "(+213) Algeria", value: "+213" },
  { label: "(+376) Andorra", value: "+376" },
  { label: "(+244) Angola", value: "+244" },
  { label: "(+672) Antarctica", value: "+672" },
  { label: "(+54) Argentina", value: "+54" },
  { label: "(+374) Armenia", value: "+374" },
  { label: "(+297) Aruba", value: "+297" },
  { label: "(+61) Australia / Christmas Island / Cocos Islands", value: "+61" },
  { label: "(+43) Austria", value: "+43" },
  { label: "(+994) Azerbaijan", value: "+994" },
  { label: "(+973) Bahrain", value: "+973" },
  { label: "(+880) Bangladesh", value: "+880" },
  { label: "(+375) Belarus", value: "+375" },
  { label: "(+32) Belgium", value: "+32" },
  { label: "(+501) Belize", value: "+501" },
  { label: "(+229) Benin", value: "+229" },
  { label: "(+975) Bhutan", value: "+975" },
  { label: "(+591) Bolivia", value: "+591" },
  { label: "(+387) Bosnia and Herzegovina", value: "+387" },
  { label: "(+267) Botswana", value: "+267" },
  { label: "(+55) Brazil", value: "+55" },
  { label: "(+246) British Indian Ocean Territory", value: "+246" },
  { label: "(+673) Brunei", value: "+673" },
  { label: "(+359) Bulgaria", value: "+359" },
  { label: "(+226) Burkina Faso", value: "+226" },
  { label: "(+257) Burundi", value: "+257" },
  { label: "(+855) Cambodia", value: "+855" },
  { label: "(+237) Cameroon", value: "+237" },
  { label: "(+238) Cape Verde", value: "+238" },
  { label: "(+236) Central African Republic", value: "+236" },
  { label: "(+235) Chad", value: "+235" },
  { label: "(+56) Chile", value: "+56" },
  { label: "(+86) China", value: "+86" },
  { label: "(+57) Colombia", value: "+57" },
  { label: "(+269) Comoros", value: "+269" },
  { label: "(+682) Cook Islands", value: "+682" },
  { label: "(+506) Costa Rica", value: "+506" },
  { label: "(+385) Croatia", value: "+385" },
  { label: "(+53) Cuba", value: "+53" },
  { label: "(+357) Cyprus", value: "+357" },
  { label: "(+420) Czech Republic", value: "+420" },
  { label: "(+243) Democratic Republic of the Congo", value: "+243" },
  { label: "(+45) Denmark", value: "+45" },
  { label: "(+253) Djibouti", value: "+253" },
  { label: "(+670) East Timor", value: "+670" },
  { label: "(+593) Ecuador", value: "+593" },
  { label: "(+20) Egypt", value: "+20" },
  { label: "(+503) El Salvador", value: "+503" },
  { label: "(+240) Equatorial Guinea", value: "+240" },
  { label: "(+291) Eritrea", value: "+291" },
  { label: "(+372) Estonia", value: "+372" },
  { label: "(+251) Ethiopia", value: "+251" },
  { label: "(+500) Falkland Islands", value: "+500" },
  { label: "(+298) Faroe Islands", value: "+298" },
  { label: "(+679) Fiji", value: "+679" },
  { label: "(+358) Finland", value: "+358" },
  { label: "(+33) France", value: "+33" },
  { label: "(+689) French Polynesia", value: "+689" },
  { label: "(+241) Gabon", value: "+241" },
  { label: "(+220) Gambia", value: "+220" },
  { label: "(+995) Georgia", value: "+995" },
  { label: "(+49) Germany", value: "+49" },
  { label: "(+233) Ghana", value: "+233" },
  { label: "(+350) Gibraltar", value: "+350" },
  { label: "(+30) Greece", value: "+30" },
  { label: "(+299) Greenland", value: "+299" },
  { label: "(+502) Guatemala", value: "+502" },
  { label: "(+224) Guinea", value: "+224" },
  { label: "(+245) Guinea-Bissau", value: "+245" },
  { label: "(+592) Guyana", value: "+592" },
  { label: "(+509) Haiti", value: "+509" },
  { label: "(+504) Honduras", value: "+504" },
  { label: "(+852) Hong Kong", value: "+852" },
  { label: "(+36) Hungary", value: "+36" },
  { label: "(+354) Iceland", value: "+354" },
  { label: "(+91) India", value: "+91" },
  { label: "(+62) Indonesia", value: "+62" },
  { label: "(+98) Iran", value: "+98" },
  { label: "(+964) Iraq", value: "+964" },
  { label: "(+353) Ireland", value: "+353" },
  { label: "(+972) Israel", value: "+972" },
  { label: "(+39) Italy", value: "+39" },
  { label: "(+225) Ivory Coast", value: "+225" },
  { label: "(+81) Japan", value: "+81" },
  { label: "(+962) Jordan", value: "+962" },
  { label: "(+254) Kenya", value: "+254" },
  { label: "(+686) Kiribati", value: "+686" },
  { label: "(+383) Kosovo", value: "+383" },
  { label: "(+965) Kuwait", value: "+965" },
  { label: "(+996) Kyrgyzstan", value: "+996" },
  { label: "(+856) Laos", value: "+856" },
  { label: "(+371) Latvia", value: "+371" },
  { label: "(+961) Lebanon", value: "+961" },
  { label: "(+266) Lesotho", value: "+266" },
  { label: "(+231) Liberia", value: "+231" },
  { label: "(+218) Libya", value: "+218" },
  { label: "(+423) Liechtenstein", value: "+423" },
  { label: "(+370) Lithuania", value: "+370" },
  { label: "(+352) Luxembourg", value: "+352" },
  { label: "(+853) Macau", value: "+853" },
  { label: "(+389) Macedonia", value: "+389" },
  { label: "(+261) Madagascar", value: "+261" },
  { label: "(+265) Malawi", value: "+265" },
  { label: "(+60) Malaysia", value: "+60" },
  { label: "(+960) Maldives", value: "+960" },
  { label: "(+223) Mali", value: "+223" },
  { label: "(+356) Malta", value: "+356" },
  { label: "(+692) Marshall Islands", value: "+692" },
  { label: "(+222) Mauritania", value: "+222" },
  { label: "(+230) Mauritius", value: "+230" },
  { label: "(+52) Mexico", value: "+52" },
  { label: "(+691) Micronesia", value: "+691" },
  { label: "(+373) Moldova", value: "+373" },
  { label: "(+377) Monaco", value: "+377" },
  { label: "(+976) Mongolia", value: "+976" },
  { label: "(+382) Montenegro", value: "+382" },
  { label: "(+212) Morocco / Western Sahara", value: "+212" },
  { label: "(+258) Mozambique", value: "+258" },
  { label: "(+95) Myanmar", value: "+95" },
  { label: "(+264) Namibia", value: "+264" },
  { label: "(+674) Nauru", value: "+674" },
  { label: "(+977) Nepal", value: "+977" },
  { label: "(+31) Netherlands", value: "+31" },
  { label: "(+599) Netherlands Antilles / Curacao", value: "+599" },
  { label: "(+687) New Caledonia", value: "+687" },
  { label: "(+64) New Zealand / Pitcairn", value: "+64" },
  { label: "(+505) Nicaragua", value: "+505" },
  { label: "(+227) Niger", value: "+227" },
  { label: "(+234) Nigeria", value: "+234" },
  { label: "(+683) Niue", value: "+683" },
  { label: "(+850) North Korea", value: "+850" },
  { label: "(+47) Norway / Svalbard and Jan Mayen", value: "+47" },
  { label: "(+968) Oman", value: "+968" },
  { label: "(+92) Pakistan", value: "+92" },
  { label: "(+680) Palau", value: "+680" },
  { label: "(+970) Palestine", value: "+970" },
  { label: "(+507) Panama", value: "+507" },
  { label: "(+675) Papua New Guinea", value: "+675" },
  { label: "(+595) Paraguay", value: "+595" },
  { label: "(+51) Peru", value: "+51" },
  { label: "(+63) Philippines", value: "+63" },
  { label: "(+48) Poland", value: "+48" },
  { label: "(+351) Portugal", value: "+351" },
  { label: "(+974) Qatar", value: "+974" },
  { label: "(+242) Republic of the Congo", value: "+242" },
  { label: "(+262) Reunion / Mayotte", value: "+262" },
  { label: "(+40) Romania", value: "+40" },
  { label: "(+7) Russia / Kazakhstan", value: "+7" },
  { label: "(+250) Rwanda", value: "+250" },
  { label: "(+590) Saint Barthelemy / Saint Martin", value: "+590" },
  { label: "(+290) Saint Helena", value: "+290" },
  { label: "(+508) Saint Pierre and Miquelon", value: "+508" },
  { label: "(+685) Samoa", value: "+685" },
  { label: "(+378) San Marino", value: "+378" },
  { label: "(+239) Sao Tome and Principe", value: "+239" },
  { label: "(+966) Saudi Arabia", value: "+966" },
  { label: "(+221) Senegal", value: "+221" },
  { label: "(+381) Serbia", value: "+381" },
  { label: "(+248) Seychelles", value: "+248" },
  { label: "(+232) Sierra Leone", value: "+232" },
  { label: "(+65) Singapore", value: "+65" },
  { label: "(+421) Slovakia", value: "+421" },
  { label: "(+386) Slovenia", value: "+386" },
  { label: "(+677) Solomon Islands", value: "+677" },
  { label: "(+252) Somalia", value: "+252" },
  { label: "(+27) South Africa", value: "+27" },
  { label: "(+82) South Korea", value: "+82" },
  { label: "(+211) South Sudan", value: "+211" },
  { label: "(+34) Spain", value: "+34" },
  { label: "(+94) Sri Lanka", value: "+94" },
  { label: "(+249) Sudan", value: "+249" },
  { label: "(+597) Suriname", value: "+597" },
  { label: "(+268) Swaziland", value: "+268" },
  { label: "(+46) Sweden", value: "+46" },
  { label: "(+41) Switzerland", value: "+41" },
  { label: "(+963) Syria", value: "+963" },
  { label: "(+886) Taiwan", value: "+886" },
  { label: "(+992) Tajikistan", value: "+992" },
  { label: "(+255) Tanzania", value: "+255" },
  { label: "(+66) Thailand", value: "+66" },
  { label: "(+228) Togo", value: "+228" },
  { label: "(+690) Tokelau", value: "+690" },
  { label: "(+676) Tonga", value: "+676" },
  { label: "(+216) Tunisia", value: "+216" },
  { label: "(+90) Turkey", value: "+90" },
  { label: "(+993) Turkmenistan", value: "+993" },
  { label: "(+688) Tuvalu", value: "+688" },
  { label: "(+256) Uganda", value: "+256" },
  { label: "(+380) Ukraine", value: "+380" },
  { label: "(+971) United Arab Emirates", value: "+971" },
  { label: "(+44) United Kingdom", value: "+44" },
  { label: "(+1) United States / Canada", value: "+1" },
  { label: "(+598) Uruguay", value: "+598" },
  { label: "(+998) Uzbekistan", value: "+998" },
  { label: "(+678) Vanuatu", value: "+678" },
  { label: "(+379) Vatican", value: "+379" },
  { label: "(+58) Venezuela", value: "+58" },
  { label: "(+84) Vietnam", value: "+84" },
  { label: "(+681) Wallis and Futuna", value: "+681" },
  { label: "(+967) Yemen", value: "+967" },
  { label: "(+260) Zambia", value: "+260" },
  { label: "(+263) Zimbabwe", value: "+263" },
];

export const countriesCurrency = [
  { country: "Afghanistan", currencyCode: "AFN", currencySymbol: "؋" },
  { country: "Albania", currencyCode: "ALL", currencySymbol: "L" },
  { country: "Algeria", currencyCode: "DZD", currencySymbol: "د.ج" },
  { country: "Andorra", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Angola", currencyCode: "AOA", currencySymbol: "Kz" },
  { country: "Antarctica", currencyCode: null, currencySymbol: null },
  { country: "Argentina", currencyCode: "ARS", currencySymbol: "$" },
  { country: "Armenia", currencyCode: "AMD", currencySymbol: "֏" },
  { country: "Aruba", currencyCode: "AWG", currencySymbol: "ƒ" },
  { country: "Australia", currencyCode: "AUD", currencySymbol: "$" },
  { country: "Austria", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Azerbaijan", currencyCode: "AZN", currencySymbol: "₼" },
  { country: "Bahrain", currencyCode: "BHD", currencySymbol: ".د.ب" },
  { country: "Bangladesh", currencyCode: "BDT", currencySymbol: "৳" },
  { country: "Belarus", currencyCode: "BYN", currencySymbol: "Br" },
  { country: "Belgium", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Belize", currencyCode: "BZD", currencySymbol: "BZ$" },
  { country: "Benin", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Bhutan", currencyCode: "BTN", currencySymbol: "Nu." },
  { country: "Bolivia", currencyCode: "BOB", currencySymbol: "Bs." },
  {
    country: "Bosnia and Herzegovina",
    currencyCode: "BAM",
    currencySymbol: "KM",
  },
  { country: "Botswana", currencyCode: "BWP", currencySymbol: "P" },
  { country: "Brazil", currencyCode: "BRL", currencySymbol: "R$" },
  {
    country: "British Indian Ocean Territory",
    currencyCode: "USD",
    currencySymbol: "$",
  },
  { country: "Brunei", currencyCode: "BND", currencySymbol: "$" },
  { country: "Bulgaria", currencyCode: "BGN", currencySymbol: "лв" },
  { country: "Burkina Faso", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Burundi", currencyCode: "BIF", currencySymbol: "Fr" },
  { country: "Cambodia", currencyCode: "KHR", currencySymbol: "៛" },
  { country: "Cameroon", currencyCode: "XAF", currencySymbol: "Fr" },
  { country: "Canada", currencyCode: "CAD", currencySymbol: "$" },
  { country: "Cape Verde", currencyCode: "CVE", currencySymbol: "Esc" },
  {
    country: "Central African Republic",
    currencyCode: "XAF",
    currencySymbol: "Fr",
  },
  { country: "Chad", currencyCode: "XAF", currencySymbol: "Fr" },
  { country: "Chile", currencyCode: "CLP", currencySymbol: "$" },
  { country: "China", currencyCode: "CNY", currencySymbol: "¥" },
  { country: "Colombia", currencyCode: "COP", currencySymbol: "$" },
  { country: "Comoros", currencyCode: "KMF", currencySymbol: "Fr" },
  { country: "Cook Islands", currencyCode: "NZD", currencySymbol: "$" },
  { country: "Costa Rica", currencyCode: "CRC", currencySymbol: "₡" },
  { country: "Croatia", currencyCode: "HRK", currencySymbol: "kn" },
  { country: "Cuba", currencyCode: "CUP", currencySymbol: "₱" },
  { country: "Cyprus", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Czech Republic", currencyCode: "CZK", currencySymbol: "Kč" },
  {
    country: "Democratic Republic of the Congo",
    currencyCode: "CDF",
    currencySymbol: "Fr",
  },
  { country: "Denmark", currencyCode: "DKK", currencySymbol: "kr" },
  { country: "Djibouti", currencyCode: "DJF", currencySymbol: "Fr" },
  { country: "East Timor", currencyCode: "USD", currencySymbol: "$" },
  { country: "Ecuador", currencyCode: "USD", currencySymbol: "$" },
  { country: "Egypt", currencyCode: "EGP", currencySymbol: "ج.م" },
  { country: "El Salvador", currencyCode: "USD", currencySymbol: "$" },
  { country: "Equatorial Guinea", currencyCode: "XAF", currencySymbol: "Fr" },
  { country: "Eritrea", currencyCode: "ERN", currencySymbol: "Nfk" },
  { country: "Estonia", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Ethiopia", currencyCode: "ETB", currencySymbol: "Br" },
  { country: "Falkland Islands", currencyCode: "FKP", currencySymbol: "£" },
  { country: "Faroe Islands", currencyCode: "DKK", currencySymbol: "kr" },
  { country: "Fiji", currencyCode: "FJD", currencySymbol: "$" },
  { country: "Finland", currencyCode: "EUR", currencySymbol: "€" },
  { country: "France", currencyCode: "EUR", currencySymbol: "€" },
  { country: "French Polynesia", currencyCode: "XPF", currencySymbol: "Fr" },
  { country: "Gabon", currencyCode: "XAF", currencySymbol: "Fr" },
  { country: "Gambia", currencyCode: "GMD", currencySymbol: "D" },
  { country: "Georgia", currencyCode: "GEL", currencySymbol: "₾" },
  { country: "Germany", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Ghana", currencyCode: "GHS", currencySymbol: "₵" },
  { country: "Gibraltar", currencyCode: "GIP", currencySymbol: "£" },
  { country: "Greece", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Greenland", currencyCode: "DKK", currencySymbol: "kr" },
  { country: "Guatemala", currencyCode: "GTQ", currencySymbol: "Q" },
  { country: "Guinea", currencyCode: "GNF", currencySymbol: "Fr" },
  { country: "Guinea-Bissau", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Guyana", currencyCode: "GYD", currencySymbol: "$" },
  { country: "Haiti", currencyCode: "HTG", currencySymbol: "G" },
  { country: "Honduras", currencyCode: "HNL", currencySymbol: "L" },
  { country: "Hong Kong", currencyCode: "HKD", currencySymbol: "$" },
  { country: "Hungary", currencyCode: "HUF", currencySymbol: "Ft" },
  { country: "Iceland", currencyCode: "ISK", currencySymbol: "kr" },
  { country: "India", currencyCode: "INR", currencySymbol: "₹" },
  { country: "Indonesia", currencyCode: "IDR", currencySymbol: "Rp" },
  { country: "Iran", currencyCode: "IRR", currencySymbol: "﷼" },
  { country: "Iraq", currencyCode: "IQD", currencySymbol: "ع.د" },
  { country: "Ireland", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Israel", currencyCode: "ILS", currencySymbol: "₪" },
  { country: "Italy", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Ivory Coast", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Japan", currencyCode: "JPY", currencySymbol: "¥" },
  { country: "Jordan", currencyCode: "JOD", currencySymbol: "د.ا" },
  { country: "Kenya", currencyCode: "KES", currencySymbol: "KSh" },
  { country: "Kiribati", currencyCode: "AUD", currencySymbol: "$" },
  { country: "Kosovo", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Kuwait", currencyCode: "KWD", currencySymbol: "د.ك" },
  { country: "Kyrgyzstan", currencyCode: "KGS", currencySymbol: "лв" },
  { country: "Laos", currencyCode: "LAK", currencySymbol: "₭" },
  { country: "Latvia", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Lebanon", currencyCode: "LBP", currencySymbol: "ل.ل" },
  { country: "Lesotho", currencyCode: "LSL", currencySymbol: "L" },
  { country: "Liberia", currencyCode: "LRD", currencySymbol: "$" },
  { country: "Libya", currencyCode: "LYD", currencySymbol: "ل.د" },
  { country: "Liechtenstein", currencyCode: "CHF", currencySymbol: "Fr" },
  { country: "Lithuania", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Luxembourg", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Macau", currencyCode: "MOP", currencySymbol: "MOP$" },
  { country: "Macedonia", currencyCode: "MKD", currencySymbol: "ден" },
  { country: "Madagascar", currencyCode: "MGA", currencySymbol: "Ar" },
  { country: "Malawi", currencyCode: "MWK", currencySymbol: "MK" },
  { country: "Malaysia", currencyCode: "MYR", currencySymbol: "RM" },
  { country: "Maldives", currencyCode: "MVR", currencySymbol: "Rf" },
  { country: "Mali", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Malta", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Marshall Islands", currencyCode: "USD", currencySymbol: "$" },
  { country: "Mauritania", currencyCode: "MRU", currencySymbol: "UM" },
  { country: "Mauritius", currencyCode: "MUR", currencySymbol: "₨" },
  { country: "Mexico", currencyCode: "MXN", currencySymbol: "$" },
  { country: "Micronesia", currencyCode: "USD", currencySymbol: "$" },
  { country: "Moldova", currencyCode: "MDL", currencySymbol: "L" },
  { country: "Monaco", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Mongolia", currencyCode: "MNT", currencySymbol: "₮" },
  { country: "Montenegro", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Morocco", currencyCode: "MAD", currencySymbol: "د.م." },
  { country: "Mozambique", currencyCode: "MZN", currencySymbol: "MT" },
  { country: "Myanmar", currencyCode: "MMK", currencySymbol: "Ks" },
  { country: "Namibia", currencyCode: "NAD", currencySymbol: "N$" },
  { country: "Nauru", currencyCode: "AUD", currencySymbol: "$" },
  { country: "Nepal", currencyCode: "NPR", currencySymbol: "₨" },
  { country: "Netherlands", currencyCode: "EUR", currencySymbol: "€" },
  { country: "New Caledonia", currencyCode: "XPF", currencySymbol: "Fr" },
  { country: "New Zealand", currencyCode: "NZD", currencySymbol: "$" },
  { country: "Nicaragua", currencyCode: "NIO", currencySymbol: "C$" },
  { country: "Niger", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Nigeria", currencyCode: "NGN", currencySymbol: "₦" },
  { country: "Niue", currencyCode: "NZD", currencySymbol: "$" },
  { country: "North Korea", currencyCode: "KPW", currencySymbol: "₩" },
  { country: "Norway", currencyCode: "NOK", currencySymbol: "kr" },
  { country: "Oman", currencyCode: "OMR", currencySymbol: "ر.ع." },
  { country: "Pakistan", currencyCode: "PKR", currencySymbol: "₨" },
  { country: "Palau", currencyCode: "USD", currencySymbol: "$" },
  { country: "Palestine", currencyCode: "ILS", currencySymbol: "₪" },
  { country: "Panama", currencyCode: "PAB", currencySymbol: "B/." },
  { country: "Papua New Guinea", currencyCode: "PGK", currencySymbol: "K" },
  { country: "Paraguay", currencyCode: "PYG", currencySymbol: "₲" },
  { country: "Peru", currencyCode: "PEN", currencySymbol: "S/." },
  { country: "Philippines", currencyCode: "PHP", currencySymbol: "₱" },
  { country: "Poland", currencyCode: "PLN", currencySymbol: "zł" },
  { country: "Portugal", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Qatar", currencyCode: "QAR", currencySymbol: "ر.ق" },
  {
    country: "Republic of the Congo",
    currencyCode: "XAF",
    currencySymbol: "Fr",
  },
  { country: "Romania", currencyCode: "RON", currencySymbol: "lei" },
  { country: "Russia", currencyCode: "RUB", currencySymbol: "₽" },
  { country: "Rwanda", currencyCode: "RWF", currencySymbol: "Fr" },
  { country: "Saint Barthelemy", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Saint Helena", currencyCode: "SHP", currencySymbol: "£" },
  {
    country: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
    currencySymbol: "€",
  },
  { country: "Samoa", currencyCode: "WST", currencySymbol: "T" },
  { country: "San Marino", currencyCode: "EUR", currencySymbol: "€" },
  {
    country: "Sao Tome and Principe",
    currencyCode: "STN",
    currencySymbol: "Db",
  },
  { country: "Saudi Arabia", currencyCode: "SAR", currencySymbol: "ر.س" },
  { country: "Senegal", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Serbia", currencyCode: "RSD", currencySymbol: "дин." },
  { country: "Seychelles", currencyCode: "SCR", currencySymbol: "₨" },
  { country: "Sierra Leone", currencyCode: "SLL", currencySymbol: "Le" },
  { country: "Singapore", currencyCode: "SGD", currencySymbol: "$" },
  { country: "Slovakia", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Slovenia", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Solomon Islands", currencyCode: "SBD", currencySymbol: "SI$" },
  { country: "Somalia", currencyCode: "SOS", currencySymbol: "Sh" },
  { country: "South Africa", currencyCode: "ZAR", currencySymbol: "R" },
  { country: "South Korea", currencyCode: "KRW", currencySymbol: "₩" },
  { country: "South Sudan", currencyCode: "SSP", currencySymbol: "£" },
  { country: "Spain", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Sri Lanka", currencyCode: "LKR", currencySymbol: "Rs" },
  { country: "Sudan", currencyCode: "SDG", currencySymbol: "ج.س" },
  { country: "Suriname", currencyCode: "SRD", currencySymbol: "$" },
  { country: "Swaziland", currencyCode: "SZL", currencySymbol: "E" },
  { country: "Sweden", currencyCode: "SEK", currencySymbol: "kr" },
  { country: "Switzerland", currencyCode: "CHF", currencySymbol: "Fr" },
  { country: "Syria", currencyCode: "SYP", currencySymbol: "ل.س" },
  { country: "Taiwan", currencyCode: "TWD", currencySymbol: "NT$" },
  { country: "Tajikistan", currencyCode: "TJS", currencySymbol: "ЅМ" },
  { country: "Tanzania", currencyCode: "TZS", currencySymbol: "Sh" },
  { country: "Thailand", currencyCode: "THB", currencySymbol: "฿" },
  { country: "Togo", currencyCode: "XOF", currencySymbol: "Fr" },
  { country: "Tonga", currencyCode: "TOP", currencySymbol: "T$" },
  {
    country: "Trinidad and Tobago",
    currencyCode: "TTD",
    currencySymbol: "TT$",
  },
  { country: "Tunisia", currencyCode: "TND", currencySymbol: "د.ت" },
  { country: "Turkey", currencyCode: "TRY", currencySymbol: "₺" },
  { country: "Turkmenistan", currencyCode: "TMT", currencySymbol: "T" },
  { country: "Tuvalu", currencyCode: "AUD", currencySymbol: "$" },
  { country: "Uganda", currencyCode: "UGX", currencySymbol: "Sh" },
  { country: "Ukraine", currencyCode: "UAH", currencySymbol: "₴" },
  {
    country: "United Arab Emirates",
    currencyCode: "AED",
    currencySymbol: "د.إ",
  },
  { country: "United Kingdom", currencyCode: "GBP", currencySymbol: "£" },
  { country: "United States", currencyCode: "USD", currencySymbol: "$" },
  { country: "Uruguay", currencyCode: "UYU", currencySymbol: "$U" },
  { country: "Uzbekistan", currencyCode: "UZS", currencySymbol: "лв" },
  { country: "Vanuatu", currencyCode: "VUV", currencySymbol: "VT" },
  { country: "Vatican City", currencyCode: "EUR", currencySymbol: "€" },
  { country: "Venezuela", currencyCode: "VES", currencySymbol: "Bs.S" },
  { country: "Vietnam", currencyCode: "VND", currencySymbol: "₫" },
  { country: "Yemen", currencyCode: "YER", currencySymbol: "﷼" },
  { country: "Zambia", currencyCode: "ZMW", currencySymbol: "ZK" },
  { country: "Zimbabwe", currencyCode: "ZWL", currencySymbol: "Z$" },
];

export const customNotificationTone = [
  {
    label: "Notification (Default Tone)",
    id: "1",
    url: "/notification-alert/bell-notification.wav",
  },
  {
    label: "Bell Notification",
    id: "2",
    url: "/notification-alert/correct-answer.wav",
  },
  {
    label: "Simple Notification",
    id: "3",
    url: "/notification-alert/happy-bells.wav",
  },
  {
    label: "Correct Answer",
    id: "4",
    url: "/notification-alert/simple-notification.wav",
  },
  {
    label: "Software Interface",
    id: "5",
    url: "/notification-alert/software-interface.wav",
  },
  {
    label: "Rhythmic Alert",
    id: "6",
    url: "/notification-alert/rhythmic-alert.mp3",
  },
  {
    label: "Long Alert",
    id: "7",
    url: "/notification-alert/long-alert.wav",
  },
];

export const serviceProviderList = [
  { label: "Zendesk", value: "Zendesk" },
  { label: "Hubspot", value: "Hubspot" },
  { label: "Freshdesk", value: "Freshdesk" },
  { label: "Zoho", value: "Zoho" },
  { label: "Shopify", value: "Shopify" },
  { label: "WooCommerce", value: "WooCommerce" },
];

export const httpMethods = [
  { label: "POST", value: "POST" },
  { label: "PUT", value: "PUT" },
  { label: "DELETE", value: "DELETE" },
  { label: "PATCH", value: "PATCH" },
];

export const httpFormats = [{ label: "JSON", value: "JSON" }];

export const authenticationTypes = [
  { label: "No Authentication", value: "NONE" },
  { label: "Bearer Token", value: "BEARER" },
  { label: "Basic Authentication", value: "BASIC" },
  { label: "API Key", value: "API-KEY" },
];

export const webhookTypes = [
  { label: "External", value: "external" },
  { label: "Contact Webhook", value: "createContactFromResponse" },
  { label: "Email Notification", value: "autoEmailTrigger" },
  { label: "WhatsApp Notification", value: "whatsappNotification" },
];

export const headerTypes = [
  { label: "Accept", value: "accept" },
  { label: "Accept-Encoding", value: "accept-encoding" },
  { label: "Authorization", value: "authorization" },
  { label: "Keep-Alive", value: "keep-alivept" },
  { label: "User-Agent", value: "user-agent" },
  { label: "Accept-Charset", value: "accept-charset" },
  { label: "Accept-Language", value: "accept-language" },
  { label: "Content-Length'", value: "content-length'" },
  { label: "Cache-Control", value: "cache-control" },
  { label: "Connection", value: "connection" },
  { label: "Content-Type", value: "content-type" },
];

export const assignTags = [
  { label: "Channel", value: "channel" },
  { label: "Category", value: "category" },
  { label: "Location", value: "location" },
  { label: "Dynamic", value: "dynamic" },
];
export const modules = [{ label: "Responses", value: "responses" }];

export const languageList = [
  {
    label: "Afrikaans",
    value: "af",
  },
  {
    label: "Albanian",
    value: "sq",
  },
  {
    label: "Arabic",
    value: "ar",
  },
  {
    label: "Azerbaijani",
    value: "az",
  },
  {
    label: "Bengali",
    value: "bn",
  },
  {
    label: "Bulgarian",
    value: "bg",
  },
  {
    label: "Catalan",
    value: "ca",
  },
  {
    label: "Croatian",
    value: "hr",
  },
  {
    label: "Czech",
    value: "cs",
  },
  {
    label: "Danish",
    value: "da",
  },
  {
    label: "Dutch",
    value: "nl",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Estonian",
    value: "et",
  },
  {
    label: "Filipino",
    value: "fil",
  },
  {
    label: "Finnish",
    value: "fi",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Georgian",
    value: "ka",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Greek",
    value: "el",
  },
  {
    label: "Gujarati",
    value: "gu",
  },
  {
    label: "Hausa",
    value: "ha",
  },
  {
    label: "Hebrew",
    value: "he",
  },
  {
    label: "Hindi",
    value: "hi",
  },
  {
    label: "Hungarian",
    value: "hu",
  },
  {
    label: "Indonesian",
    value: "id",
  },
  {
    label: "Irish",
    value: "ga",
  },
  {
    label: "Italian",
    value: "it",
  },
  {
    label: "Japanese",
    value: "ja",
  },
  {
    label: "Kannada",
    value: "kn",
  },
  {
    label: "Kazakh",
    value: "kk",
  },
  {
    label: "Kinyarwanda",
    value: "rw_RW",
  },
  {
    label: "Korean",
    value: "ko",
  },
  {
    label: "Kyrgyz (Kyrgyzstan)",
    value: "ky_KG",
  },
  {
    label: "Lao",
    value: "lo",
  },
  {
    label: "Latvian",
    value: "lv",
  },
  {
    label: "Lithuanian",
    value: "lt",
  },
  {
    label: "Macedonian",
    value: "mk",
  },
  {
    label: "Malay",
    value: "ms",
  },
  {
    label: "Malayalam",
    value: "ml",
  },
  {
    label: "Marathi",
    value: "mr",
  },
  {
    label: "Norwegian",
    value: "nb",
  },
  {
    label: "Persian",
    value: "fa",
  },
  {
    label: "Polish",
    value: "pl",
  },
  {
    label: "Portuguese",
    value: "pt",
  },
  {
    label: "Punjabi",
    value: "pa",
  },
  {
    label: "Romanian",
    value: "ro",
  },
  {
    label: "Russian",
    value: "ru",
  },
  {
    label: "Serbian",
    value: "sr",
  },
  {
    label: "Slovak",
    value: "sk",
  },
  {
    label: "Slovenian",
    value: "sl",
  },
  {
    label: "Spanish",
    value: "es",
  },
  {
    label: "Swahili",
    value: "sw",
  },
  {
    label: "Swedish",
    value: "sv",
  },
  {
    label: "Tamil",
    value: "ta",
  },
  {
    label: "Telugu",
    value: "te",
  },
  {
    label: "Thai",
    value: "th",
  },
  {
    label: "Turkish",
    value: "tr",
  },
  {
    label: "Ukrainian",
    value: "uk",
  },
  {
    label: "Urdu",
    value: "ur",
  },
  {
    label: "Uzbek",
    value: "uz",
  },
  {
    label: "Vietnamese",
    value: "vi",
  },
  {
    label: "Zulu",
    value: "zu",
  },
];

export const cannedResponsesAvailableFor = {
  MYSELF: {
    displayName: "Myself",
    logo: "/images/canned-responses-icons/person.png",
  },
  GLOBAL: {
    displayName: "Global",
    logo: "/images/canned-responses-icons/public.png",
  },
};

export const quickReplyIcon = {
  quickReplyColoredIcon:
    "/images/liveagent-canned-responses-icons/quickReply.png",
  quickReplyGreyIcon:
    "images/liveagent-canned-responses-icons/quickReplyGreyColor.png",
};

////*******************************************////
////**************** CONVERSATION *************////
////*******************************************////
export const conversationStatus = {
  waitingForLiveAgent: {
    text: "Awaiting Agent",
    style: "4px solid red",
  },
  liveConversations: {
    text: "Conversation In-progress",
    style: "4px solid green",
  },
  onHoldConversations: {
    text: "Conversation Paused",
    style: "4px solid #a1d6a1",
  },
  coAgentLiveConversations: {
    text: "Handled By Other Agent",
    style: "4px solid #00008B",
  },
};

export const liveAgentAttachment = {
  maxSize: 4096000,
};

export const fileIcon = {
  jpg: "/images/file/image.png",
  jpeg: "/images/file/image.png",
  png: "/images/file/image.png",
  pdf: "/images/file/pdf.png",
  docx: "/images/file/word.png",
  document: "/images/file/word.png",
  doc: "/images/file/word.png",
  xlsx: "/images/file/excel.png",
  xls: "/images/file/excel.png",
  sheet: "/images/file/excel.png",
  pptx: "/images/file/ppt.png",
  ppt: "/images/file/ppt.png",
  presentation: "/images/file/ppt.png",
  "ms-powerpoint": "/images/file/ppt.png",
};

export const mimeToFileType = {
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel": "xls",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
};

////*******************************************////
////**************** Live Agent Settings *************////
////*******************************************////

export const liveAgentAvailability = {
  Monday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: false,
  },
  Tuesday: {
    open: "07:00:00",
    closing: "23:30:00",
    isHoliday: false,
  },
  Wednesday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: false,
  },
  Thursday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: false,
  },
  Friday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: false,
  },
  Saturday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: true,
  },
  Sunday: {
    open: "09:00:00",
    closing: "19:30:00",
    isHoliday: true,
  },
};

////*******************************************////
////**************** UNRECOGNIZED INTENTS *************////
////*******************************************////

//Unrecognized intents status
export const unrecognizedIntentsStatus = [
  { key: "Unassigned", value: "unassigned" },
  {
    key: "Training completed",
    value: "training-completed",
  },
  {
    key: "In review",
    value: "in-review",
  },
  {
    key: "Add to Ignore",
    value: "add-to-ignore",
  },
];

export const systemUnrecognizedIntentsProperties = ["_id", "botId", "nlpScore"];

export const unrecognizedIntentsEnums = {
  assignedTo: "Assigned To",
  status: "Status",
  notes: "Notes",
  channel: "Channel",
  phrase: "Phrase",
  timeStamp: "Time Stamp",
};

////*******************************************////
////**************** MESSAGE TEMPLATES *************////
////*******************************************////

// Templates supported languages
export const templateLanguages = [
  {
    key: "af",
    value: "Afrikaans",
  },
  {
    key: "sq",
    value: "Albanian",
  },
  {
    key: "ar",
    value: "Arabic",
  },
  {
    key: "az",
    value: "Azerbaijani",
  },
  {
    key: "bn",
    value: "Bengali",
  },
  {
    key: "bg",
    value: "Bulgarian",
  },
  {
    key: "ca",
    value: "Catalan",
  },
  {
    key: "zh_CN",
    value: "Chinese (CHN)",
  },
  {
    key: "zh_HK",
    value: "Chinese (HKG)",
  },
  {
    key: "zh_TW",
    value: "Chinese (TAI)",
  },
  {
    key: "hr",
    value: "Croatian",
  },
  {
    key: "cs",
    value: "Czech",
  },
  {
    key: "da",
    value: "Danish",
  },
  {
    key: "nl",
    value: "Dutch",
  },
  {
    key: "en",
    value: "English",
  },
  {
    key: "en_GB",
    value: "English (UK)",
  },
  {
    key: "en_US",
    value: "English (US)",
  },
  {
    key: "et",
    value: "Estonian",
  },
  {
    key: "fil",
    value: "Filipino",
  },
  {
    key: "fi",
    value: "Finnish",
  },
  {
    key: "fr",
    value: "French",
  },
  {
    key: "ka",
    value: "Georgian",
  },
  {
    key: "de",
    value: "German",
  },
  {
    key: "el",
    value: "Greek",
  },
  {
    key: "gu",
    value: "Gujarati",
  },
  {
    key: "ha",
    value: "Hausa",
  },
  {
    key: "he",
    value: "Hebrew",
  },
  {
    key: "hi",
    value: "Hindi",
  },
  {
    key: "hu",
    value: "Hungarian",
  },
  {
    key: "id",
    value: "Indonesian",
  },
  {
    key: "ga",
    value: "Irish",
  },
  {
    key: "it",
    value: "Italian",
  },
  {
    key: "ja",
    value: "Japanese",
  },
  {
    key: "kn",
    value: "Kannada",
  },
  {
    key: "kk",
    value: "Kazakh",
  },
  {
    key: "rw_RW",
    value: "Kinyarwanda",
  },
  {
    key: "ko",
    value: "Korean",
  },
  {
    key: "ky_KG",
    value: "Kyrgyz (Kyrgyzstan)",
  },
  {
    key: "lo",
    value: "Lao",
  },
  {
    key: "lv",
    value: "Latvian",
  },
  {
    key: "lt",
    value: "Lithuanian",
  },
  {
    key: "mk",
    value: "Macedonian",
  },
  {
    key: "ms",
    value: "Malay",
  },
  {
    key: "ml",
    value: "Malayalam",
  },
  {
    key: "mr",
    value: "Marathi",
  },
  {
    key: "nb",
    value: "Norwegian",
  },
  {
    key: "fa",
    value: "Persian",
  },
  {
    key: "pl",
    value: "Polish",
  },
  {
    key: "pt_BR",
    value: "Portuguese (BR)",
  },
  {
    key: "pt_PT",
    value: "Portuguese (POR)",
  },
  {
    key: "pa",
    value: "Punjabi",
  },
  {
    key: "ro",
    value: "Romanian",
  },
  {
    key: "ru",
    value: "Russian",
  },
  {
    key: "sr",
    value: "Serbian",
  },
  {
    key: "sk",
    value: "Slovak",
  },
  {
    key: "sl",
    value: "Slovenian",
  },
  {
    key: "es",
    value: "Spanish",
  },
  {
    key: "es_AR",
    value: "Spanish (ARG)",
  },
  {
    key: "es_ES",
    value: "Spanish (SPA)",
  },
  {
    key: "es_MX",
    value: "Spanish (MEX)",
  },
  {
    key: "sw",
    value: "Swahili",
  },
  {
    key: "sv",
    value: "Swedish",
  },
  {
    key: "ta",
    value: "Tamil",
  },
  {
    key: "te",
    value: "Telugu",
  },
  {
    key: "th",
    value: "Thai",
  },
  {
    key: "tr",
    value: "Turkish",
  },
  {
    key: "uk",
    value: "Ukrainian",
  },
  {
    key: "ur",
    value: "Urdu",
  },
  {
    key: "uz",
    value: "Uzbek",
  },
  {
    key: "vi",
    value: "Vietnamese",
  },
  {
    key: "zu",
    value: "Zulu",
  },
];

export const templateStatusIcon = {
  submitted: "/images/message-templates/submitted.png",
  pending: "/images/message-templates/pending.png",
  approved: "/images/message-templates/approved.png",
  rejected: "/images/message-templates/rejected.png",
};

////*******************************************////
////**************** DELIVERY SERVICES *************////
////*******************************************////

// Lalamove supported languages
export const lalamoveLanguages = [
  {
    key: "en_BR",
    value: "Brasil - English",
  },
  {
    key: "pt_BR",
    value: "Brasil - Portuguese",
  },
  {
    key: "en_HK",
    value: "Hong Kong - English",
  },
  {
    key: "zh_HK",
    value: "Hong Kong - Chinese",
  },
  {
    key: "en_ID",
    value: "Indonesia - English",
  },
  {
    key: "id_ID",
    value: "Indonesia - Bahasa",
  },
  {
    key: "en_MY",
    value: "Malaysia - English",
  },
  {
    key: "ms_MY",
    value: "Malaysia - Malay",
  },
  {
    key: "en_MX",
    value: "Mexico - English",
  },
  {
    key: "es_MX",
    value: "Mexico - Spanish",
  },
  {
    key: "en_PH",
    value: "Philippines - English",
  },
  {
    key: "en_SG",
    value: "Singapore - English",
  },
  {
    key: "zh_TW",
    value: "Taiwan - Chinese",
  },
  {
    key: "th_TH",
    value: "Thailand - Thai",
  },
  {
    key: "en_TH",
    value: "Thailand - English",
  },
  {
    key: "en_VN",
    value: "Vietnam - English",
  },
  {
    key: "vi_VN",
    value: "Vietnam - Vietnamese",
  },
];

export const lalamoveMarkets = [
  {
    key: "ID",
    value: "Indonesia",
  },
  {
    key: "MY",
    value: "Malaysia",
  },
  {
    key: "SG",
    value: "Singapore",
  },
];

export const lalamoveServiceTypes = [
  {
    key: "MOTORCYCLE",
    value: "Motor Cycle",
  },
  {
    key: "MPV",
    value: "MPV",
  },

  {
    key: "CAR",
    value: "Car",
  },
  {
    key: "VAN",
    value: "Van",
  },
  {
    key: "TRUCK330",
    value: "Truck 330",
  },
  {
    key: "TRUCK550",
    value: "Truck 550",
  },
];

export const delayOptions = [
  { key: "Immediate", value: 0 },
  { key: "15 minutes", value: 15 * 60 },
  { key: "30 minutes", value: 30 * 60 },
  { key: "45 minutes", value: 45 * 60 },
  { key: "1 hour", value: 60 * 60 },
  { key: "2 hours", value: 2 * 60 * 60 },
  { key: "3 hours", value: 3 * 60 * 60 },
];

export const lalamoveStatus = [
  {
    key: "PENDING",
    value: "Pending",
  },
  {
    key: "DELIVERED",
    value: "Delivered",
  },
  {
    key: "SIGNED",
    value: "Signed",
  },
  {
    key: "FAILED",
    value: "Failed",
  },
  {
    key: "EXPIRED",
    value: "Expired",
  },
  {
    key: "ASSIGNING_DRIVER",
    value: "Assigning Driver",
  },
];

////*******************************************////
////**************** WhatsApp FLOWS *************////
////*******************************************////

export const whatsappFlowsCategories = [
  {
    key: "SIGN_UP",
    value: "Sign up",
  },
  {
    key: "SIGN_IN",
    value: "Sign in",
  },
  {
    key: "APPOINTMENT_BOOKING",
    value: "Appointment Booking",
  },
  {
    key: "LEAD_GENERATION",
    value: "Lead Generation",
  },
  {
    key: "CONTACT_US",
    value: "Contact Us",
  },
  {
    key: "CUSTOMER_SUPPORT",
    value: "Customer Support",
  },
  {
    key: "SURVEY",
    value: "Survey",
  },
  {
    key: "OTHER",
    value: "Other",
  },
];

export const platformWebhooks = [
  {
    name: "WooCommerce",
    value: "WOOCOMMERCE",
  },
  {
    name: "HubSpot",
    value: "HUBSPOT",
  },
  {
    name: "Shopify",
    value: "SHOPIFY",
  },
  {
    name: "Big Commerce",
    value: "BIGCOMMERCE",
  },
  {
    name: "Magento",
    value: "MAGENTO",
  },
  {
    name: "Presta Shop",
    value: "PRESTASHOP",
  },
  {
    name: "Open Cart",
    value: "OPENCART",
  },
  {
    name: "Wix eCommerce",
    value: "WIXECOMMERCE",
  },
  {
    name: "Squarespace Commerce",
    value: "SQUAREUSECOMMERCE",
  },
  {
    name: "Others",
    value: "OTHERS",
  },
];

export const wooCommerceService = [
  "ORDERS",
  "PRODUCTS",
  "REFUNDS",
  "CUSTOMERS",
  "COUPONS",
  "SHIPPING METHODS",
];
