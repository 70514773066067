import React, { useState } from "react";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  Modal,
  Button,
  Checkbox,
  Space,
  Row,
  Col,
  Image,
  Typography,
} from "antd";
import "./BotWHATSAPP.less";
import { URIs } from "../../../constants/env";

const { Title, Link } = Typography;

const BotWHATSAPPOnboarding = ({ botToBeEdited }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const [checkedList, setCheckedList] = useState([]);

  const launchWhatsAppSignup = () => {
    // Use the Facebook SDK to log in the user and obtain the code
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;

          dispatchMiddleware({
            type: "ONBOARD_WHATSAPP_USING_META",
            payload: {
              code: code,
              component: "configure-whatsapp-button",
              currentBot: botToBeEdited,
            },
          });

          console.log("code--->", code);
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "895398902370292", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {},
      }
    );
  };

  return (
    <Modal
      open={
        state.infoPanel["configure-whatsapp-info-panel"] &&
        state.infoPanel["configure-whatsapp-info-panel"].visible
      }
      destroyOnClose={true}
      footer={null}
      onCancel={() => {
        dispatchReducer({
          type: "CLOSE_INFO_PANEL",
          payload: {
            component: "configure-whatsapp-info-panel",
          },
        });
      }}
      width={800}
      style={{
        top: 40,
      }}
      className="whatsapp-onboarding-modal"
    >
      <Row gutter={[0, 25]}>
        <Col
          span={24}
          style={{
            background: "#1b7d1e",
            fontSize: "14px",
            color: "white",
          }}
          className="whatsapp-onboarding-modal-header"
        >
          iNextLabs is an authorized tech provider for WhatsApp Business
          Account. We provide a seamless integration of WhatsApp Business API
          with your existing CRM, ERP, or any other business application. We
          also provide a complete solution for WhatsApp Business API setup,
          configuration, and maintenance.
        </Col>

        <Col span={24} className="whatsapp-onboarding-modal-content">
          <Row gutter={[0, 15]}>
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <Image
                src="/inextlabs-logo.png"
                preview={false}
                style={{
                  width: "240px",
                }}
              />
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "20px",
              }}
            >
              <Title level={2}>WhatsApp Embedded Signup</Title>
            </Col>

            <Col span={24}>
              Let us get you access to Meta (Facebook) WhatsApp Business
              Platform. Before you proceed, please make sure you have:
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "10px",
              }}
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(checkedList) => setCheckedList(checkedList)}
                value={checkedList}
                className="whatsapp-onboarding-modal-check-list"
              >
                <Checkbox value="Facebook Account">A Facebook account</Checkbox>
                <Checkbox value="Phone Number">
                  A phone number that is not already registered with WhatsApp
                  Business API
                </Checkbox>
                <Checkbox value="Website Status">
                  Your company has a website that is active and explains your
                  company's business model
                </Checkbox>
              </Checkbox.Group>
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "30px",
              }}
            >
              By clicking the button below, you agree to our{" "}
              <Link href={`${URIs.WEBSITE}/privacy-policy`} target="_blank">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link href={`${URIs.WEBSITE}/terms-of-service`} target="_blank">
                Terms of Service
              </Link>
              .
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "3px",
              }}
            >
              <Button
                type="primary"
                size="large"
                onClick={() => launchWhatsAppSignup()}
                disabled={checkedList.length !== 3}
              >
                Login with Facebook
              </Button>
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "30px",
              }}
            >
              Don't have a Facebook account?{" "}
              <Link
                style={{ marginLeft: "10px" }}
                href={URIs.FACEBOOK}
                target="_blank"
              >
                Sign up
              </Link>
            </Col>

            <Col
              span={24}
              style={{
                marginBottom: 25,
                marginTop: -5,
              }}
            >
              <hr
                style={{
                  border: "1px solid grey",
                }}
              />
              <Space
                style={{
                  marginTop: 10,
                }}
              >
                © 2021 iNextLabs. All rights reserved.
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default BotWHATSAPPOnboarding;
