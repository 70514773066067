import { Col, Row, Layout, Divider } from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useConnectMiddleware from "../../connect/connectMiddleware";
import ClientResponsesTable from "./ClientResponsesTable";
import ClientResponsesHeader from "./ClientResponsesHeader";
import ClientResponsesFeedbackChart from "./ClientResponsesFeedbackChart";
import ClientResponsesInfoPanel from "./ClientResponsesInfoPanel";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import {
  responseDropdownColumns,
  responseFieldsToInclude,
} from "../../constants/enums";
import { mode } from "../../constants/env";
import ClientResponsesInfoPanelV2 from "./ClientResponsesInfoPanelV2";
import ClientResponsesViewConversationPanel from "./ClientResponsesViewConversationPanel";

const ClientResponsesList = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const currentResponseCategory = state.clientResponses.currentResponseCategory;

  useConnectMiddleware(
    () => {
      dispatchReducer({ type: "RD_CLEAR_RESPONSES" });
      dispatchMiddleware({
        type: "MWD_LOAD_RESPONSES_DATA",
        payload: {
          component: "client-responses-list",
          currentResponseCategory,
          pageNumber: 1,
        },
      });
      dispatchMiddleware({
        type: "MWD_LOAD_RESPONSE_PROPERTIES",
        payload: {
          component: "client-responses-properties",
          currentResponseCategory,
        },
      });
    },
    {
      currentResponseCategory,
    }
  );

  let enums = state.application.enums?.responses?.data || [];

  //Create client response columns as JSON
  let clientResponsesColumns = Array.from(
    new Map(
      state.clientResponses?.responses?.data
        ?.flatMap((clientResponse) =>
          Object.entries(clientResponse)?.map(([key]) => ({
            title: key,
            dataIndex: key,
            key,
            // To disable filters, if the value is an array or object.
            value: clientResponse[key],
          }))
        )
        ?.map((column) => [column.key, column])
    ).values()
  );

  //Check column contains status, assigned to if no means to add column
  //Add responseFieldsToInclude columns to client response columns
  let filteredResponseColumns = responseDropdownColumns
    .concat(responseFieldsToInclude)
    .filter(
      (responseColumn) =>
        !clientResponsesColumns.some(
          (clientResponse) => clientResponse.key === responseColumn
        )
    );
  filteredResponseColumns = filteredResponseColumns?.map((responseColumn) => ({
    title: responseColumn,
    key: responseColumn,
  }));
  clientResponsesColumns.push(...filteredResponseColumns);

  //Match client response column with enums
  clientResponsesColumns = clientResponsesColumns?.map(
    (clientResponseColumn) => {
      const matchedClientResponseColumn = enums.find(
        (enumKey) => enumKey._id === clientResponseColumn.key
      );
      const {
        display_name = clientResponseColumn.key,
        display,
        display_order,
        filter,
      } = matchedClientResponseColumn || {};
      return {
        ...clientResponseColumn,
        title: display_name,
        display,
        displayOrder: parseInt(display_order),
        filter,
      };
    }
  );

  //Filtering client response column based on display property
  clientResponsesColumns = clientResponsesColumns?.filter(
    (clientResponseColumn) =>
      clientResponseColumn.display !== false &&
      clientResponseColumn.display !== undefined
  );

  //Client responses columns Sort
  clientResponsesColumns = clientResponsesColumns?.sort((a, b) => {
    if (a.displayOrder && b.displayOrder) {
      return (a.displayOrder || 0) - (b.displayOrder || 0);
    } else {
      return (b.displayOrder || 0) - (a.displayOrder || 0);
    }
  });

  //For Feedback chart visible
  let isFeedbackChartVisible =
    ["DEV", "STAGING"].includes(mode) &&
    clientResponsesColumns.some(
      (responseColumn) =>
        responseColumn.key === "chart" && responseColumn.display
    ) &&
    currentResponseCategory.toLowerCase() === "feedback";

  let selectedResponse = state.clientResponses.responses?.data.find(
    (response) =>
      response._id === state.filters.actionRowKey["client-responses-table"]
  );

  let isContactAlreadyExist;

  if (selectedResponse?.channel.toUpperCase() === "WHATSAPP") {
    selectedResponse.conversationId =
      selectedResponse.conversationId.startsWith("+")
        ? selectedResponse.conversationId
        : "+" + selectedResponse.conversationId;
  }

  state.contactManager.contacts?.forEach((contact) => {
    Object.keys(contact).forEach((key) => {
      if (
        contact[key] === selectedResponse?.conversationId ||
        contact[key] === selectedResponse?.userPhoneNumber
      ) {
        isContactAlreadyExist = contact;
      }
    });
  });

  return (
    <>
      {state.clientResponses.responses ? (
        <>
          {state.apiCallStatus["client-responses-properties"] &&
          state.apiCallStatus["client-responses-properties"].loading ? (
            <FullPageLoader
              component="client-responses-properties"
              loadingMessage="Loading... Please wait..."
            />
          ) : (
            <Layout style={{ background: "#ffffff" }}>
              <Divider type="vertical" style={{ height: "100%" }} />
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <ClientResponsesHeader
                    clientResponsesColumns={clientResponsesColumns}
                  />
                </Col>
                <Col span={24}>
                  {isFeedbackChartVisible && <ClientResponsesFeedbackChart />}
                </Col>
                <Col span={24}>
                  <ClientResponsesTable
                    isFeedbackChartVisible={isFeedbackChartVisible}
                  />
                </Col>
              </Row>
              {selectedResponse?.version === 2 ? (
                <ClientResponsesInfoPanelV2
                  selectedResponse={selectedResponse}
                  isContactAlreadyExist={isContactAlreadyExist}
                />
              ) : (
                <ClientResponsesInfoPanel
                  isContactAlreadyExist={isContactAlreadyExist}
                />
              )}
              <ClientResponsesViewConversationPanel />
            </Layout>
          )}
        </>
      ) : (
        <FullPageLoader
          component="client-responses-list"
          loadingMessage="Loading... Please wait..."
        />
      )}
    </>
  );
};

export default ClientResponsesList;
